import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { endpoints } from 'global/endpoints';

const useStoresAndAgentsPWA = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetching stores and agents data when the component mounts
    axios
      .get(endpoints.getStoresAndAgentsPWA)
      .then((response) => {
        //console.log('Stores and Agents Data:', response.data);
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching stores and agents data:', error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  return { data, isLoading, error };
};

export default useStoresAndAgentsPWA;
