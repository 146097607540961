import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const Button = ({
  text,
  primaryButton,
  nextButton,
  backButton,
  submitButton,
  disabled,
  onClick,
  centerAlign,
  leadsStylesButton,
}) => (
  <div
    className={classnames(
      centerAlign && styles.centerAlign,
      submitButton && styles.submitButtonWrap
    )}
  >
    <button
      disabled={disabled}
      className={classnames(
        styles.button,
        primaryButton && styles.primary,
        nextButton && styles.next,
        backButton && styles.back,
        submitButton && styles.submit,
        leadsStylesButton && styles.leadsStylesButton
      )}
      onClick={onClick}
    >
      {text}
    </button>
  </div>
);

export default Button;
