import React from 'react';
import { ModalHeaderElavtal } from './ModalHeaderElavtal';
import styles from '../../../../src/components/Modal/styles.module.scss';

export const ModalElavtal = ({
  children,
  handleMinimizedModal,
  headerTitle,
  setIsMinimizes,
  handleOpenModal,
  modalTextInfo,
}) => (
  <div>
    <div className={styles.modalFadeShow}></div>
    <div className={styles.modalWrapper}>
      <div className={styles.modal}>
        <ModalHeaderElavtal
          title={headerTitle}
          handleMinimizedModal={handleMinimizedModal}
          handleOpenModal={handleOpenModal}
          setIsMinimizes={setIsMinimizes}
          modalTextInfo={modalTextInfo}
        />
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  </div>
);
