import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const Icon = ({ icon, active, className }) => {
  return (
    <div
      className={classnames(
        className,
        styles.iconWrap,
        active ? styles.iconWrapActive : styles.iconWrapNotActive
      )}
    >
      <FontAwesomeIcon icon={icon} className={styles.icon} />
    </div>
  );
};
