function getAge(dateString) {
  var now = new Date();
  var yearNow = now.getYear();
  var monthNow = now.getMonth();
  var dateNow = now.getDate();
  var monthAge;
  var dateAge;

  var dob = new Date(
    dateString.substring(6, 10),
    dateString.substring(0, 2) - 1,
    dateString.substring(3, 5)
  );

  var yearDob = dob.getYear();
  var monthDob = dob.getMonth();
  var dateDob = dob.getDate();
  var age = {};
  let yearAge = yearNow - yearDob;

  if (monthNow >= monthDob) monthAge = monthNow - monthDob;
  else {
    yearAge--;
    monthAge = 12 + monthNow - monthDob;
  }

  if (dateNow >= dateDob) dateAge = dateNow - dateDob;
  else {
    monthAge--;
    dateAge = 31 + dateNow - dateDob;

    if (monthAge < 0) {
      monthAge = 11;
      yearAge--;
    }
  }

  age = {
    years: yearAge,
    months: monthAge,
    days: dateAge,
  };

  return age;
}

export const ageOverLimit = (date) => {
  const personalNumberLength = 11;
  const thisYear = new Date().getFullYear() % 100;
  const birthYear =
    date.length === personalNumberLength && date.substring(0, 2);
  const shouldCheck = birthYear > thisYear;

  const dateOfBirth = date.substring(0, 6);
  const yearOfBirth = dateOfBirth.substring(0, 2);
  const monthOfBirth = dateOfBirth.substring(2, 4);
  const dayOfBirth = dateOfBirth.substring(4, 6);

  const newDateOfBirth = `${monthOfBirth}/${dayOfBirth}/${yearOfBirth}`;

  const result = getAge(newDateOfBirth);

  return shouldCheck && result.years >= 75;
};
