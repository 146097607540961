import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Text } from 'components/Text';
import { dateFormats, fourDaysFromNow } from 'utils/constants';
import styles from './styles.module.scss';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';
import { format, addDays } from 'date-fns';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';
import { useLocation } from 'react-router';

export const DatePickerComponent = ({ label, required, assignment }) => {
  const {
    facilityStartDate,
    setFacilityStartDate,
    startDateProxyOne,
    setStartDateProxyOne,
    startDate,
  } = useContext(GlobalContextContainer);

  const location = useLocation();
  const { move_status } = assignment.data;

  const [conditionalStartDate, setConditionalStartDate] = useState(null);

  useEffect(() => {
    let newConditionalStartDate;
    if (
      pathLocationCheck(location.pathname) === Routes.ELON ||
      pathLocationCheck(location.pathname) === Routes.PCE
    ) {
      newConditionalStartDate = addDays(new Date(), 21);
      const formatedDate = format(
        newConditionalStartDate,
        dateFormats.yearMonthDay
      );
      if (move_status === '1') {
        setFacilityStartDate(formatedDate);
      } else {
        setStartDateProxyOne(formatedDate);
      }
    } else {
      newConditionalStartDate =
        move_status === '1' ? new Date(startDate) : fourDaysFromNow;
    }
    setConditionalStartDate(newConditionalStartDate);
  }, [
    location.pathname,
    move_status,
    setFacilityStartDate,
    setStartDateProxyOne,
    startDate,
  ]);

  const handleOnChangeDate = (date) => {
    const newDate = date || conditionalStartDate;
    const formatedDate = format(newDate, dateFormats.yearMonthDay);

    if (move_status === '1') {
      setFacilityStartDate(formatedDate);
    } else {
      setStartDateProxyOne(formatedDate);
    }
  };

  return (
    <div className={styles.datePickerWrap}>
      {label && (
        <Text medium className={styles.datePickerLabel}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Text>
      )}
      {conditionalStartDate && (
        <DatePicker
          selected={
            move_status === '1'
              ? new Date(facilityStartDate)
              : new Date(startDateProxyOne)
          }
          onChange={handleOnChangeDate}
          showDisabledMonthNavigation
          dateFormat={dateFormats.yearMonthDay}
          className={styles.datePickerInput}
          calendarClassName={styles.monthContainer}
          minDate={conditionalStartDate}
        />
      )}
    </div>
  );
};
