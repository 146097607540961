import { agreementOptions, existingAddress } from './constants';
import {
  UPDATE_PERSONAL_NUMBER,
  UPDATE_USER_INFORMATION,
  UPDATE_SAME_ADDRESS,
  UPDATE_MOVE_STATUS,
  CHECK_ADDRESS,
  SET_INVOICE_ADDRESS,
  USER_EXISTING,
  UPDATE_FACILITY_INFORMATION_AREA_ID,
  UPDATE_FACILITY_INFORMATION_PLANT_ID,
  UPDATE_FACILITY_INFORMATION_CONTRACT_START,
  SELECTED_STORE,
  AGENT_ID,
  EMAIL,
  PHONE_NUMBER,
  RESET_MODAL,
  UPDATE_PROXY,
  UPLOADED_PHOTO,
  TOGGLE_BUTTON,
  UPLOADED_IMAGE_NAME,
  AGREEMENT_OPTIONS,
  UPDATE_AGENT_ID,
  UPDATE_BANK_ID,
  UPLOAD_PHOTO,
  SET_BILLING_ADDRESS,
  UPDATE_ACCOMODATION,
  UPDATE_CONTRACT_CHECK,
} from './reducerConstants';

export const DefaultAssignment = {
  userExisting: {},
  stepsValidation: {
    securityNumber: false,
    facilityInformation: false,
  },
  address: {
    address: '',
    zip_code: '',
  },
  data: {
    soc_id: '',
    first_name: '',
    last_name: '',
    email: '',
    cell_phone: '',
    address: '',
    zip_code: '',
    city: '',
    proxy: '0',
    move_status: '1',
    agent_id: '',
    invoice_address: '1',
    store_id: '',
    bank_id_sign: 0,
    file: '',
    //new values
    building_type: '0',
    creator_confirmation: '0',
  },
  addressExisting: existingAddress.initial,
  toggleButton: false,
  agreementOptions: agreementOptions,
  fileName: '',
  agentId: '',
  billingAddress: {},
};

export const AssignmentReducer = (assignment, { type, payload }) => {
  switch (type) {
    case UPDATE_PERSONAL_NUMBER:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          soc_id: payload.soc_id,
        },
      };
    case UPDATE_USER_INFORMATION:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          first_name: payload.first_name,
          last_name: payload.last_name,
          address: payload.address,
          zip_code: payload.zip_code,
          city: payload.city,
        },
      };
    case UPDATE_SAME_ADDRESS:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          invoice_address: payload,
        },
      };
    case UPDATE_MOVE_STATUS:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          move_status: payload,
        },
      };
    case CHECK_ADDRESS:
      return {
        ...assignment,
        addressExisting: payload,
      };
    case SET_INVOICE_ADDRESS:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          address2: payload.address2,
          zip_code2: payload.zip_code2,
          city2: payload.city2,
        },
      };
    case USER_EXISTING:
      return {
        ...assignment,
        userExisting: payload,
      };
    case UPDATE_FACILITY_INFORMATION_AREA_ID:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          net_area: payload,
        },
      };
    case UPDATE_FACILITY_INFORMATION_PLANT_ID:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          object_id: payload,
        },
      };
    case UPDATE_FACILITY_INFORMATION_CONTRACT_START:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          start_date: payload,
        },
      };

    case SELECTED_STORE:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          store_id: payload,
        },
      };

    case AGENT_ID:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          agent_id: payload,
        },
      };

    case EMAIL:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          email: payload,
        },
      };

    case PHONE_NUMBER:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          cell_phone: payload,
        },
      };

    case UPDATE_PROXY:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          proxy: payload,
        },
      };

    case UPLOADED_PHOTO:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          file: payload,
        },
      };

    case UPLOADED_IMAGE_NAME:
      return {
        ...assignment,
        fileName: payload,
      };

    case UPLOAD_PHOTO: {
      const { file, fileName } = payload;

      return {
        ...assignment,
        fileName,
        data: {
          ...assignment.data,
          file,
        },
      };
    }

    case TOGGLE_BUTTON:
      return {
        ...assignment,
        toggleButton: payload,
      };

    case AGREEMENT_OPTIONS:
      return {
        ...assignment,
        agreementOptions: payload,
      };
    case UPDATE_AGENT_ID: {
      return {
        ...assignment,
        agentId: payload,
      };
    }
    case UPDATE_BANK_ID: {
      return {
        ...assignment,
        fileName: payload.bank_id_sign ? '' : assignment.fileName,
        data: {
          ...assignment.data,
          bank_id_sign: payload.bank_id_sign,
          bank_id_signature: payload.bank_id_signature,
          file: payload.bank_id_sign ? '' : assignment.data.file,
        },
      };
    }

    case SET_BILLING_ADDRESS: {
      return {
        ...assignment,
        billingAddress: {
          address2: payload.address,
          zip_code2: payload.zip_code,
          city2: payload.city,
        },
      };
    }
    case UPDATE_ACCOMODATION:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          building_type: payload,
        },
      };
    case UPDATE_CONTRACT_CHECK:
      return {
        ...assignment,
        data: {
          ...assignment.data,
          creator_confirmation: payload,
        },
      };
    case RESET_MODAL:
      return DefaultAssignment;

    default:
      return assignment;
  }
};
