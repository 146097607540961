import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalElavtal } from './utils/ModalElavtal';
import ElavtalMain from './utils/Elavtal/ElavtalMain';
import { ChooseAvtal } from './utils/ChooseAvtal';
import { Header } from './utils/Header';
import Badges from './utils/Badges';
import { AssignmentModalContent } from 'containers/AssignmentModalContent';

import { Routes } from 'global/Routes';
import {
  pathLocationCheck,
  shortCodeOrganisation,
} from 'utils/shortCodeOrganisation';
import { MinimizedModalElavtal } from './utils/Elavtal/MinimizedModalElavtal';
import useStoresAndAgentsPWA from './utils/StoreAndAgents';
import { ChangeAreaCode } from './utils/Elavtal/ChangeAreaCode';

import './utils/styles.css';
import thumbsUp from './Img/thumbsup.png';
import tryggKundkontakt from './Img/tryggkundkontakt.png';
import reco2024 from './Img/reco2024.svg';
import tvaaRIRad from './Img/2arirad.svg';

import { GlobalContextContainer } from '../../containers/MediaMarkt/globalContext';
import { IntervalContext } from '../../containers/AssignmentModalContent/components/IntervalContext/intervalContext';

import axios from 'axios';
import { endpoints } from 'global/endpoints';

export const ELON = () => {
  const location = useLocation();
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(12);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [stores, setStores] = useState();
  const [showModal, setShowModal] = useState('hidden');
  const [loading, setIsLoading] = useState(true);
  const [areaCode, setAreaCode] = useState('3');
  const [resetKey, setResetKey] = useState(Date.now());
  const [disposeAndResetScrive, setDisposeAndResetScrive] = useState(false);

  const [timeUpdated, setTimeUpdated] = useState(new Date().getTime());

  const callsToMake = async () => {
    const allItemsInLocalStorage = JSON.parse(
      localStorage.getItem(
        `${
          pathLocationCheck(location.pathname) === Routes.LEADS
            ? 'leads'
            : 'contracts'
        }${shortCodeOrganisation(location.pathname)}`
      )
    );

    let deletedItems = [];
    for (let i = 0; i < allItemsInLocalStorage.length; i++) {
      if (navigator.onLine) {
        await axios
          .post(
            pathLocationCheck(location.pathname) === Routes.LEADS
              ? endpoints.assignLeads
              : endpoints.assignContract,
            allItemsInLocalStorage[i]
          )
          .then((res) => {
            if (res.status === 200) {
              deletedItems.push(allItemsInLocalStorage[i]);

              if (i + 1 === allItemsInLocalStorage.length) {
                const ItemsToStay = allItemsInLocalStorage.filter(
                  (el) => !deletedItems.includes(el)
                );
                localStorage.setItem(
                  `${
                    pathLocationCheck(location.pathname) === Routes.LEADS
                      ? 'leads'
                      : 'contracts'
                  }${shortCodeOrganisation(location.pathname)}`,
                  JSON.stringify(ItemsToStay)
                );
                setTimeUpdated(new Date().getTime());
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const makeCallFromLocalStorage = useCallback(() => {
    const allItems = JSON.parse(
      localStorage.getItem(
        `${
          pathLocationCheck(location.pathname) === Routes.LEADS
            ? 'leads'
            : 'contracts'
        }${shortCodeOrganisation(location.pathname)}`
      )
    );
    if (allItems && allItems.length > 0) {
      callsToMake();
    }
  }, [location]);

  const handleAreaCode = (id) => {
    setAreaCode(id);
  };

  const { agentsList, setAgentsList } = useContext(GlobalContextContainer);

  const { intervalIdRef, isCancelledRef } = useContext(IntervalContext);

  const { data, error } = useStoresAndAgentsPWA();

  useEffect(() => {
    if (
      (!isModalOpen && isMinimized) ||
      (!isModalOpen && !isMinimized) ||
      loading
    ) {
      setShowModal('hidden');
      setResetKey(Date.now());
    } else {
      setShowModal('visible');
      setDisposeAndResetScrive(false);
    }
    if (!isModalOpen && !isMinimized) {
      setDisposeAndResetScrive(true);
      if (intervalIdRef.current) {
        clearTimeout(intervalIdRef.current);
        intervalIdRef.current = null;
      }
      isCancelledRef.current = true;
    }
  }, [isModalOpen, loading]);

  useEffect(() => {
    if (data) {
      setStores(data.stores);
      setAgentsList(data.agents);
      //console.log('Fetched Stores and Agents Data:', data);
      setIsLoading(false);
    }
    if (error) {
      console.error('Error:', error);
    }
  }, [data, error]);

  const handleSelect = (index) => {
    setSelectedBox(index);
  };

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
  };

  const handleSubmit = async () => {
    console.log(
      'set pricegroup id directly in backend depending on org id = 57 instead '
    );
  };

  const handleOpenModal = (isModalOpen) => {
    setIsModalOpen(isModalOpen);
    if (!isModalOpen) {
      setIsMinimized(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsMinimized(false);
  };

  const handleMinimizeModal = (state) => {
    setShowModal(state);
  };
  const callSetIsMinimized = (state) => {
    setIsMinimized(state);
  };

  const handleMaximizeModal = () => {
    setIsMinimized(false);
    setShowModal('visible');
    setIsModalOpen(true);
  };

  useEffect(() => {
    // Enable scrolling only if the modal is not open or it is minimized.
    const shouldEnableScroll = !isModalOpen || isMinimized;
    document.body.classList.toggle('no-scroll', !shouldEnableScroll);

    // Cleanup function to ensure no-scroll is removed when component unmounts or conditions change
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen, isMinimized]); // Include isMinimized in the dependency array

  return (
    <div className="container">
      <Header
        image={thumbsUp}
        title="Upplev fördelarna med rörligt elpris "
        title2="Teckna nytt elavtal idag!"
        body="Använd kraften av ett rörligt elpris som följer elbörsen och dra nytta av låga priser över tid. Perfekt för dig som kan styra din energiförbrukning"
        checkboxOne="0 kr månadsavgift första 6 månaderna (ord. pris 59 kr/mån)"
        checkboxTwo="Ingen bindningstid (1 mån uppsägningstid)"
        checkboxThree="Tillval Solkraft till 4 öre/kWh (5 öre inkl. moms)"
        checkboxFour="Tillval Vintersäkra - möjlighet att teckna fastpris över vintern (0kr i 6 månader, ord. pris 29kr/mån)"
      />

      <ChangeAreaCode areaCode={areaCode} setAreaCode={handleAreaCode} />
      <ElavtalMain
        selectedBox={selectedBox}
        handleSelect={handleSelect}
        selectedMonth={selectedMonth}
        handleMonthSelect={handleMonthSelect}
        areaCode={areaCode}
      />
      <ChooseAvtal
        onSubmit={handleSubmit}
        handleOpenModal={handleOpenModal}
        index={selectedBox}
        selectedMonth={selectedMonth}
        loading={loading}
        isModalOpen={isModalOpen}
      />

      {isMinimized && showModal === 'hidden' && (
        <MinimizedModalElavtal
          closeModal={handleCloseModal}
          handleMaximize={handleMaximizeModal}
          handleMinimizeModal={handleMinimizeModal}
        />
      )}

      <Badges badges={[tryggKundkontakt, reco2024, tvaaRIRad]} />

      {isModalOpen && !loading && (
        <div style={{ visibility: showModal }}>
          <ModalElavtal
            handleMinimizedModal={handleMinimizeModal}
            headerTitle={'Registrera kund'}
            setIsMinimizes={callSetIsMinimized}
            handleOpenModal={handleOpenModal}
            modalTextInfo={'TIMPRIS ELON KAMPANJ'}
          >
            <AssignmentModalContent
              resetKey={resetKey}
              isOpen={isModalOpen}
              isMinimized={isMinimized}
              setIsOnline={true} //navigator.onLine
              makeCallFromLocalStorage={makeCallFromLocalStorage} //makeCallFromLocalStorage => if problems set to null
              storeData={stores}
              agents={agentsList}
              disposeAndResetScrive={disposeAndResetScrive}
              setDisposeAndResetScrive={setDisposeAndResetScrive}
            />
          </ModalElavtal>
        </div>
      )}
    </div>
  );
};

export default ELON;
