import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const Text = ({
  small,
  medium,
  mediumSecondary,
  large,
  xl,
  bold,
  primary,
  secondary,
  white,
  children,
  className,
  error,
}) => (
  <div
    className={classnames(
      styles.text,
      small && styles.small,
      medium && styles.medium,
      mediumSecondary && styles.mediumSecondary,
      large && styles.large,
      xl && styles.xl,
      bold && styles.bold,
      primary && styles.primary,
      secondary && styles.secondary,
      white && styles.white,
      error && styles.error,
      className
    )}
  >
    {children}
  </div>
);
