import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { endpoints } from 'global/endpoints';

export const ChangeAreaCode = ({ areaCode, setAreaCode }) => {
  const [placeholder, setPlaceholder] = useState('Postnummer');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [helpText, setHelpText] = useState('');
  const [helpTextClassName, setHelpTextClassName] = useState('');
  const [helpInputClassName, setHelpInputClassName] = useState('');

  const handlePostnummer = (val) => {
    setHelpTextClassName('');
    setHelpInputClassName('');
    setLoading(true);
    const loadingIntervalId = setLoadingState();
    //console.log(val);
    axios
      .get(`${endpoints.changeRegionData}?zip_code=${val}`)
      .then((res) => {
        if (res.data && Number(res.data.id) !== 100) {
          setAreaCode(res.data.id);
          clearInterval(loadingIntervalId);
          setHelpTextClassName('helptext-success');
          setHelpText('Postnummer uppdaterades till elområde ' + res.data.id);
        } else {
          clearInterval(loadingIntervalId);
          setHelpTextClassName('helptext-error');
          setHelpInputClassName('helpinput-error');
          setHelpText('Kontrollera Postnummer');
        }
      })
      .catch((err) => {
        console.log(err);
        clearInterval(loadingIntervalId);
        setHelpTextClassName('helptext-error');
        setHelpText('Kontrollera Postnummer');
      });
    setLoading(false);
  };
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 5) {
      setValue(newValue);
    }
    if (newValue.length === 5) {
      handlePostnummer(e.target.value);
    }
    if (newValue.length === 0) {
      setHelpInputClassName('');
      setHelpText('');
    }
  };

  const setLoadingState = () => {
    let loadingStates = [
      'Validerar postnummer ',
      'Validerar postnummer .',
      'Validerar postnummer . .',
      'Validerar postnummer . . .',
    ];
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % loadingStates.length;
      const loadingState = loadingStates[index];
      setHelpText(loadingState);
    }, 400);

    return intervalId; // Return the interval ID
  };

  return (
    <div className="change-areacode-wrapper">
      <p className="change-area-text">
        Detta avtal gäller elområde {areaCode}, för att byta elområde skriv in
        postnummer
      </p>
      <div className={`change-input-container`}>
        <input
          type="number"
          maxLength={5}
          minLength={5}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          className={`change-input ${helpInputClassName}`}
        />
        {/* <button
          type="button"
          onClick={handlePostnummer}
          disabled={value.length !== 5 || loading}
        >
          UPPDATERA
  </button> */}
      </div>
      <p className={`change-area-helptext ${helpTextClassName}`}>{helpText}</p>
    </div>
  );
};

export default ChangeAreaCode;
