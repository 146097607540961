import React from 'react';
import { useLocation } from 'react-router-dom';

import { Image } from 'components/Image';
import ConditionalRender from 'components/ConditionalRender';

import telinetLogo from 'global/images/Logo.png';
import mediaMarktLogo from 'global/images/media-markt.png';
import prisgarantiLogo from 'global/images/prisgaranti.jpg';
import stodBarncancerFonden from 'global/images/stod-barncancer-fonden-new.png';
import { Routes } from 'global/Routes';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';

import styles from './styles.module.scss';

export const Brands = () => {
  const location = useLocation();
  return (
    <div className={styles.brandsWrap}>
      <ConditionalRender
        shouldRender={
          pathLocationCheck(location.pathname) === Routes.MEDIA_MARKT ||
          pathLocationCheck(location.pathname) === Routes.PERSONAL_BEST ||
          pathLocationCheck(location.pathname) === Routes.LEADS
        }
      >
        <Image imageUrl={mediaMarktLogo} alt="media markt" />
      </ConditionalRender>
      <Image imageUrl={telinetLogo} alt="telinet energy" />
      <ConditionalRender
        shouldRender={pathLocationCheck(location.pathname) !== Routes.PCE}
      >
        <Image imageUrl={prisgarantiLogo} alt="pris garanti" />
      </ConditionalRender>
      <Image imageUrl={stodBarncancerFonden} alt="stod barncancerfonden" />
    </div>
  );
};
