import React from 'react';
import { useLocation } from 'react-router-dom';

import ConditionalRender from 'components/ConditionalRender';
import { Text } from 'components/Text';
import { Title } from 'components/Title';

import { Routes } from 'global/Routes';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import styles from './styles.module.scss';

import { endpoints } from 'global/endpoints';

export const InfoBlock = () => {
  const location = useLocation();
  const infoLink = endpoints.priceInfoUrl;
  return (
    <div>
      <Title
        title={
          pathLocationCheck(location.pathname) === Routes.TIMPRIS
            ? 'Rörligt förnybart med timpris'
            : 'Rörligt pris'
        }
        large
      />
      <Text large>
        <p>
          För dig som uppskattar det rörliga prisets fördelar med lågt pris över
          tid.
        </p>
      </Text>
      <Text large>
        <div className={styles.columnWrapper}>
          <ConditionalRender
            shouldRender={
              pathLocationCheck(location.pathname) === Routes.MEDIA_MARKT ||
              pathLocationCheck(location.pathname) === Routes.PERSONAL_BEST ||
              pathLocationCheck(location.pathname) === Routes.LEADS
            }
          >
            <p>
              500 kr att handla för{' '}
              {pathLocationCheck(location.pathname) !== Routes.LEADS &&
                '– direkt nu i butiken!'}
            </p>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={pathLocationCheck(location.pathname) === Routes.EVENT}
          >
            <p>0 kr i månadsavgift första 3 månaderna!</p>
            <p>300 kr att handla för via GoGift</p>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={
              pathLocationCheck(location.pathname) === Routes.TIMPRIS
            }
          >
            <p>0 kr i månadsavgift första 3 månaderna!</p>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={pathLocationCheck(location.pathname) === Routes.PCE}
          >
            <ul style={{ paddingLeft: '20px' }}>
              <li>Rörligt pris som följer elbörsen!</li>
              <li>
                *0kr i månadsavgift första 6 månaderna (ord. pris 59kr/mån)
              </li>
              <li>Tillval: 100% solkraft 4 öre/kWh (5 öre/kWh inkl moms)</li>
              <li>
                Tillval Vintersäkra - möjlighet att teckna rabatterat fastpris
                över vintern (0kr i 6 månader, ord. pris 29kr/mån)
              </li>
              <li>Ingen bindningstid</li>
              <li>1 mån uppsägningstid</li>
            </ul>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={pathLocationCheck(location.pathname) !== Routes.PCE}
          >
            <p>Prisgaranti – så länge du är kvar som kund!</p>
            <p>
              {pathLocationCheck(location.pathname) === Routes.TIMPRIS
                ? `Rörligt timpris`
                : `Rörligt pris`}{' '}
              som följer elbörsen
            </p>
            <ConditionalRender
              shouldRender={
                pathLocationCheck(location.pathname) !== Routes.TIMPRIS
              }
            >
              {(pathLocationCheck(location.pathname) === Routes.PERSONAL_BEST ||
                pathLocationCheck(location.pathname) === Routes.LEADS) &&
              navigator.onLine ? (
                <p>
                  <a href={infoLink}>Pristak</a> – försäkring mot prisstegringar
                </p>
              ) : (
                <p>
                  Vintersäkra – möjlighet att teckna rabatterat fast pris över
                  vintern (0 kr i 3 mån! ord. pris 29 kr/mån)
                </p>
              )}
            </ConditionalRender>
            <p>0 mån bindningstid</p>
            <p>1 mån uppsägningstid</p>
          </ConditionalRender>
        </div>
      </Text>
    </div>
  );
};
