import React from 'react';

import { BoxWrapper } from 'components/BoxWrapper';

import { FeesAndTaxes } from 'containers/FeesAndTaxes';
import { ComparativePrice } from 'containers/ComparativePrice';
import { ComparisonPrice } from 'containers/ComparisonPrice';

import styles from './styles.module.scss';

export const Prices = ({ data, loading, error }) => (
  <div className={styles.prices}>
    <div className={styles.container} id="feesAndTaxes">
      <div className={styles.pricesWrapper}>
        <BoxWrapper light>
          <FeesAndTaxes data={data} loading={loading} error={error} />
        </BoxWrapper>
        <BoxWrapper light className={styles.comparativePriceWrap}>
          <ComparativePrice />
        </BoxWrapper>
        <ComparisonPrice data={data} loading={loading} error={error} />
      </div>
    </div>
  </div>
);
