import React, { useState, useEffect } from 'react';

import { Title } from 'components/Title';
import { Input } from 'components/Input';
import BottomButtons from '../BottomButtons';

import { SET_INVOICE_ADDRESS } from 'utils/reducerConstants';
import {
  postalCodeValidation,
  handleOnFocus,
  lengthValidation,
} from 'utils/userInformationValidations';

import styles from './styles.module.scss';

const BillingAddress = ({
  assignment,
  setAssignmentData,
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
}) => {
  const [stepValidations, setStepValidations] = useState({});
  const [validStep, setStepValid] = useState(false);
  const userData = assignment.data;
  const [billingAddress] = useState({
    address2: assignment.billingAddress.address2 || '',
    zip_code2: assignment.billingAddress.zip_code2 || '',
    city2: assignment.billingAddress.city2 || '',
  });

  const address2 =
    userData.address2 === undefined
      ? billingAddress.address2
      : userData.address2;
  const zip_code2 =
    userData.zip_code2 === undefined
      ? billingAddress.zip_code2
      : userData.zip_code2;
  const city2 =
    userData.city2 === undefined ? billingAddress.city2 : userData.city2;

  const repopulateData = () => {
    if (
      userData.address2 === undefined ||
      userData.zip_code2 === undefined ||
      userData.city2 === undefined
    ) {
      setAssignmentData({
        type: SET_INVOICE_ADDRESS,
        payload: {
          ...assignment.data,
          address2: billingAddress.address2,
          zip_code2: billingAddress.zip_code2,
          city2: billingAddress.city2,
        },
      });
    }
  };

  const handleInputChange = (val) => {
    setAssignmentData({
      type: SET_INVOICE_ADDRESS,
      payload: { ...assignment.data, [val.target.name]: val.target.value },
    });
  };

  const handleInputsValidation = () => {
    let newState = {};
    const inputsNames = ['address2', 'city2', 'zip_code2'];

    inputsNames.forEach((el) =>
      (
        el === 'zip_code2'
          ? postalCodeValidation(userData[el])
          : lengthValidation(userData[el])
      )
        ? (newState = { ...newState, [el]: true })
        : (newState = { ...newState, [el]: false })
    );

    setStepValidations(newState);
  };

  const handleClickNextButton = () => {
    handleInputsValidation();
    stepValidations.address2 &&
    stepValidations.city2 &&
    stepValidations.zip_code2
      ? setStepValid(true)
      : setStepValid(false);
  };

  const isStepValidOnMount = () => {
    lengthValidation(userData.address2) &&
    lengthValidation(userData.city2) &&
    postalCodeValidation(userData.zip_code2)
      ? setStepValid(true)
      : setStepValid(false);
  };

  useEffect(() => {
    isStepValidOnMount();
    repopulateData();
  });
  return (
    <div>
      <Title title="Faktureringsadress" smallStatic center />
      <div className={styles.inputsWrap}>
        <div className={styles.twoColumns}>
          <Input
            required={true}
            label="Address"
            placeholder="Address..."
            large
            name="address2"
            value={address2}
            onChange={(e) => handleInputChange(e)}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.address2 === false}
          />
          <Input
            required={true}
            label="Postkod"
            placeholder="Postkod..."
            large
            name="zip_code2"
            value={zip_code2}
            onChange={(e) => handleInputChange(e)}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.zip_code2 === false}
            maxLength={7}
          />
        </div>
        <div className={styles.twoColumns}>
          <Input
            required={true}
            label="Postort "
            placeholder="Postort ..."
            large
            name="city2"
            value={city2}
            onChange={(e) => handleInputChange(e)}
            onFocus={(e) => handleOnFocus(e.target.name, setStepValidations)}
            error={stepValidations.city2 === false}
          />
          <div />
        </div>
      </div>
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={validStep}
        handleClick={() => handleClickNextButton()}
      />
    </div>
  );
};

export default BillingAddress;
