import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Title } from 'components/Title';
import { DropDown } from 'components/DropDown';
import BottomButtons from '../BottomButtons';
import { GlobalContextContainer } from '../../../MediaMarkt/globalContext';

import styles from './styles.module.scss';

import { getKeyByValue } from 'utils/datePicker';
import { shortCodeOrganisation } from 'utils/shortCodeOrganisation';

const Store = ({
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  setAssignmentData,
  assignment,
  storeData,
}) => {
  const { store_id } = assignment.data;
  const [stepValidation, setStepValidation] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const location = useLocation();
  const organisation = shortCodeOrganisation(location.pathname);
  const { storeOptions } = useContext(GlobalContextContainer);

  // Handle conditional storeOptionsAlt
  const storeOptionsAlt = storeData ? storeData : null;

  const handleDropdownChange = () => {
    if (Object.keys(selectedItem).length === 0) {
      setShowErrorMessage(true);
    }
  };
  const cachedStoreOptions = window.localStorage.getItem(
    `storeOptions${organisation}`
  );

  const selectedStore = getKeyByValue(
    navigator.onLine
      ? storeOptionsAlt || storeOptions
      : JSON.parse(cachedStoreOptions),
    store_id
  );

  const activeStore = store_id ? { [`${selectedStore}`]: store_id } : {};
  const [selectedItem, setSelectedItem] = useState(activeStore);

  return (
    <div className={styles.storeWrap}>
      <Title
        center
        smallStatic
        title="Välj butik:"
        className={styles.storeTitle}
      />
      <DropDown
        options={
          navigator.onLine
            ? storeOptionsAlt || storeOptions
            : JSON.parse(cachedStoreOptions) || storeOptions
        }
        center
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setStepValidation={setStepValidation}
        showErrorMessage={showErrorMessage}
        setAssignmentData={setAssignmentData}
      />
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={stepValidation}
        handleClick={handleDropdownChange}
      />
    </div>
  );
};

export default Store;
