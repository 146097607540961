import React from 'react';
import { NavigationStep } from '..';
import styles from './styles.module.scss';

const Navigation = ({ steps }) => {
  const selectedStep = steps.findIndex((step) => step.selected);
  const passedStep = steps
    .filter((step, key) => key < selectedStep)
    .map((step, key) => key);
  return (
    <div className={styles.navigationWrapper}>
      <div className={styles.navigation}>
        {steps.map((step, index) => (
          <NavigationStep
            step={step}
            key={index}
            className={styles.step}
            isPassed={passedStep.includes(index)}
          />
        ))}
      </div>
    </div>
  );
};
export default Navigation;
