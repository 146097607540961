import React from 'react';
import '../../utils/styles.css';

export const MinimizedModalElavtal = ({
  closeModal,
  handleMaximize,
  handleMinimizeModal,
}) => {
  return (
    <div className="faButtonsWrapper">
      <button onClick={handleMaximize} className="faPlusButton">
        + Öppna
      </button>
      <button onClick={closeModal} className="faMinusButton">
        × Stäng
      </button>
    </div>
  );
};
