export const stringWithOnlyNumbers = (string) => {
  return string.match(/^[0-9]+$/) != null;
};

export const areaCodeWithLetter = (areaCode) => {
  let onlyCode = null;

  if (areaCode.toLowerCase().startsWith('s-') && areaCode.length === 7) {
    onlyCode = areaCode.substr(2);
  }
  return onlyCode;
};

export const areaCodeValidations = (areaCode) => {
  if (
    (stringWithOnlyNumbers(areaCode) && areaCode.length === 5) ||
    (areaCode.toLowerCase().startsWith('s-') && areaCode.length === 7)
  ) {
    return true;
  }
};

export const areaCodeWithoutLetters = (areaCode) => {
  return areaCode.match(/^\d+$/) ? areaCode : areaCodeWithLetter(areaCode);
};
