export class Routes {
  //static PERSONAL_BEST = '/m01112019m';
  //static MEDIA_MARKT = '/1234mediamarkt';
  static EVENT = '/200622event_1';
  static TIMPRIS = '/promo2022timpris';
  static PCE = '/pcecampaign';
  static QUIZ = '/pcequiz';
  //static LEADS = '/leads20211001mema';
  //static EL = '/elavtal';
  static ELON = '/elon';
}
