export const endpoints = {
  chartData: process.env.REACT_APP_API_URL + '/chart-data',
  pricesData: process.env.REACT_APP_API_URL + '/prices-data',
  changeRegionData: process.env.REACT_APP_API_URL + '/change-region',
  checkUserExisting: process.env.REACT_APP_API_URL + '/customer/existing-check',
  businessCheck: process.env.REACT_APP_API_URL + '/customer/business-check',
  addressCheck:
    process.env.REACT_APP_API_URL + '/customer/existing-address-check',
  storeOptionsPb: process.env.REACT_APP_API_URL + '/pb-stores',
  storeOptionsEv: process.env.REACT_APP_API_URL + '/ev-stores',
  storeOptionsPce: process.env.REACT_APP_API_URL + '/pce-stores',
  storeOptionsMm: process.env.REACT_APP_API_URL + '/mm-stores',
  storeOptionsLe: process.env.REACT_APP_API_URL + '/leads-stores',
  storeOptionsTi: process.env.REACT_APP_API_URL + '/timpris-stores',
  noExistingUser: process.env.REACT_APP_API_URL + '/customer/credit-check',
  agentIdPb: process.env.REACT_APP_API_URL + '/pb-agents',
  agentIdEv: process.env.REACT_APP_API_URL + '/ev-agents',
  agentIdPce: process.env.REACT_APP_API_URL + '/pce-agents',
  agentIdMm: process.env.REACT_APP_API_URL + '/mm-agents',
  agentIdTi: process.env.REACT_APP_API_URL + '/timpris-agents',
  assignContract: process.env.REACT_APP_API_URL + '/contract',
  assignLeads: process.env.REACT_APP_API_URL + '/leads',
  netArea: process.env.REACT_APP_API_URL + '/netarea',
  getPriceData: process.env.REACT_APP_API_URL + '/get-pricedata',

  calculatPriceBoxData:
    process.env.REACT_APP_API_URL + '/calculate-pricebox-prices-data',
  getStoresAndAgentsPWA: process.env.REACT_APP_API_URL + '/pwa-stores-agents',
  priceGroupsToPwa: process.env.REACT_APP_API_URL + '/priceGroupsToPwa',

  startDate: process.env.REACT_APP_API_URL_V1 + '/site/start-date',
  contractQRSign: process.env.REACT_APP_API_URL + '/contract/QRsign',
  contractQRSignCheck: process.env.REACT_APP_API_URL + '/contract/QRsignCheck',
  scrivePwa: process.env.REACT_APP_API_URL + '/scrive/useAction',
  signPOAWithFile: process.env.REACT_APP_API_URL + '/signPOAFile',
  contractSignForBankId: process.env.REACT_APP_API_URL + '/contract-for-pwa',
  contractSign: process.env.REACT_APP_API_URL + '/contract/sign',
  priceInfoUrl:
    process.env.REACT_APP_BASE_URL +
    '/tillval-pristak-avtal-tecknade-via-mediamarkt/',
};
