import React from 'react';
import styles from './styles.module.scss';

const Checkbox = ({ onChange, value, name, id, label, disabled }) => (
  <div className={styles.checkboxOptionsWrapper}>
    <input
      type="checkbox"
      onChange={onChange}
      value={value}
      name={name}
      id={id}
      checked={value}
      disabled={disabled}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);

export default Checkbox;
