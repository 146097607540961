import React from 'react';
import telinetTitle from '../Img/telinet_title.png';
export const Header = ({
  image,
  title,
  title2,
  body,
  checkboxOne,
  checkboxTwo,
  checkboxThree,
  checkboxFour,
}) => {
  return (
    <div className="header">
      <img src={telinetTitle} alt={'title'} className="telinet-title-img" />

      <img src={image} alt={title} className="header-image" />
      <h1 className="_textmain header-title">
        {title} <br /> {title2}
      </h1>
      <p className="_textmain header-text">{body}</p>

      <div className="checkboxes">
        <label className="_textmain">
          <DottedCheckbox />
          {checkboxOne}
        </label>
        <label className="_textmain">
          <DottedCheckbox />
          {checkboxTwo}
        </label>
        <label className="_textmain">
          <DottedCheckbox />
          {checkboxThree}
        </label>
        <label className="_textmain">
          <DottedCheckbox />
          {checkboxFour}
        </label>
      </div>
    </div>
  );
};

const DottedCheckbox = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="dotted-checkmark"
    style={{
      width: '38px', // Set width as a string with units
      height: '38px', // Set height as a string with units
      flexShrink: 0, // Use camelCase for CSS properties in JSX
    }}
  >
    <circle cx="6" cy="12" r="2" fill="currentColor" />
    <circle cx="10" cy="16" r="2" fill="currentColor" />
    <circle cx="18" cy="6" r="2" fill="currentColor" />
    <circle cx="14" cy="10" r="2" fill="currentColor" />
  </svg>
);
