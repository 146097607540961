import React from 'react';

const ConditionalRender = ({ shouldRender, children }) => {
  if (shouldRender) {
    return children;
  }

  return <></>;
};

export default ConditionalRender;
