export const characterCounter = (value, length) => {
  if (value && value.length === length) {
    return true;
  }
  return false;
};

export const validatePlantId = (value, startingNumbers) => {
  if (characterCounter(value, 18) && value.startsWith(startingNumbers)) {
    return true;
  }
  return false;
};
