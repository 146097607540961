import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { format, addDays, interval, set } from 'date-fns';
import axios from 'axios';

import { Text } from 'components/Text';
import { RadioButton } from 'components/Buttons';
import { Input } from 'components/Input';
import { DatePickerComponent } from 'components/DatePicker';
import BottomButtons from '../BottomButtons';
import { Loader } from 'components/Loader';
import { IntervalContext } from '../IntervalContext/intervalContext';

import {
  currentFacilityInfo,
  newFacilityInfo,
  fourDaysFromNow,
  dateFormats,
} from 'utils/constants';

import styles from './styles.module.scss';
import ConditionalRender from 'components/ConditionalRender';
import { endpoints } from 'global/endpoints';
import { characterCounter, validatePlantId } from 'utils/facilityInformation';
import {
  UPDATE_FACILITY_INFORMATION_AREA_ID,
  UPDATE_FACILITY_INFORMATION_PLANT_ID,
  UPDATE_PROXY,
  UPDATE_FACILITY_INFORMATION_CONTRACT_START,
} from 'utils/reducerConstants';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';

import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';
import { useLocation } from 'react-router';
import { LoaderCopy } from 'components/ELON/utils/LoaderCopy';

const FacilityInformation = ({
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  assignment,
  setAssignmentData,
  scriveData,
  setScriveDataFunction,
  signScriveNotBankID,
  setShouldSignWithScrive,
  scriveFormData,
  private_socid,
  scriveUrl,
  setScriveUrl,
}) => {
  const { intervalIdRef, isCancelledRef } = useContext(IntervalContext);

  const {
    facilityStartDate,
    startDateProxyOne,
    startDate,
    setFacilityStartDate,
    setStartDateProxyOne,
    setValidation,
  } = useContext(GlobalContextContainer);

  const radioButtonData = [
    {
      id: 1,
      name: 'currentFacilityInfo',
      value: '',
      label: 'Jag behöver hjälp att inhämta anläggnings uppgifter',
      //label: currentFacilityInfo,
      showDatepicker: assignment.data.move_status === '2',
    },
    {
      id: 0,
      name: 'newFacilityInfo',
      value: '',
      label: newFacilityInfo,
      showDatepicker: false,
    },
  ];
  const [checkedValue, setIsChecked] = useState(
    parseInt(assignment.data.proxy)
  );

  const location = useLocation();

  const [stepValidation, setStepValidation] = useState(false);
  const [showErrorBorder, setShowErrorBorder] = useState({});
  const [plantId, setPlantId] = useState('');
  const [areaId, setAreaId] = useState('');
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [lowScore, setLowScore] = useState();
  //const [scriveUrl, setScriveUrl] = useState('');
  const [scriveDocumentId, setScriveDocumentId] = useState('');
  //const intervalIdRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [documentSigned, setDocumentSigned] = useState(false);
  const checkedValueRef = useRef(checkedValue);
  const scriveDataRef = useRef(scriveData);
  const signScriveNotBankIDRef = useRef(signScriveNotBankID);
  const scriveDocumentIdRef = useRef(scriveDocumentId);

  const isStepValid = useCallback(() => {
    return (
      validatePlantId(assignment.data.object_id || plantId, '735999') &&
      characterCounter(assignment.data.net_area, 3)
    );
  }, [assignment.data.net_area, assignment.data.object_id, plantId]);

  const creditCheck = useCallback(() => {
    if (navigator.onLine && assignment.userExisting.creditCheck) {
      setLoading(true);
      axios
        .get(
          `${endpoints.noExistingUser}?personal_number=${assignment.data.soc_id}`
        )
        .then((res) => {
          setData(res.status === 200);
          setLowScore(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 503) {
            setStepValidation(true);
            setValidation(0);
            setLoading(false);
          } else {
            setData(false);
            setLoading(false);
            setLowScore(true);
            console.error(err);
          }
        });
    } else if (assignment.addressExisting !== 'non-existing') {
      setData(true);
      setLowScore(false);
    }
  }, [
    assignment.addressExisting,
    assignment.data.soc_id,
    assignment.userExisting.creditCheck,
    setValidation,
  ]);
  const handleAreaId = (value) => {
    setAreaId(value);
    setAssignmentData({
      type: UPDATE_FACILITY_INFORMATION_AREA_ID,
      payload: value,
    });
  };

  const handlePlantId = (value) => {
    setPlantId(value);
    setAssignmentData({
      type: UPDATE_FACILITY_INFORMATION_PLANT_ID,
      payload: value,
    });
  };
  const handlevalidationFields = useCallback(() => {
    if (checkedValue === 0) {
      setShowErrorBorder({
        areaId: characterCounter(assignment.data.net_area, 3),
        plantId: validatePlantId(
          assignment.data.object_id || plantId,
          '735999'
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaId, checkedValue, plantId]);

  useEffect(() => {
    if (!assignment.userExisting.creditCheck && checkedValue === 1) {
      setStepValidation(true);
      setShouldSignWithScrive(true);
    } else {
      setStepValidation(false);
      setShouldSignWithScrive(false);
      setData(true);
    }
  }, [assignment.userExisting.creditCheck, checkedValue]);

  useEffect(() => {
    if (!scriveData && checkedValue === 1) {
      if (scriveUrl !== '') {
        checkScriveStatus();
      }
      setData(false);
    } else if (scriveData && checkedValue === 1) {
      setData(true);
    }
    const initiateScriveProcess = async () => {
      if (checkedValue === 0 || scriveUrl !== '') {
        return;
      }
      if (signScriveNotBankID) {
        // Disables the next button until the Scrive data is set
        setData(false);
        setIsLoading(true);
        setScriveUrl('');
        setError(null);

        let scriveFormData_correctSyntax = {
          firstName:
            scriveFormData.first_name || assignment.data.first_name || '',
          lastName: scriveFormData.last_name || assignment.data.last_name || '',
          personalNumber: private_socid ?? '',
          city: scriveFormData.city || assignment.data.city || '',
          postalCode: scriveFormData.zip_code || assignment.data.zip_code || '',
          address: scriveFormData.address || assignment.data.address || '',
          email: scriveFormData.email || assignment.data.email || '',
        };

        const scriveServiceUrl = endpoints.scrivePwa;

        try {
          const response = await axios.post(scriveServiceUrl, {
            action: 'completeProcess',
            formdata: scriveFormData_correctSyntax,
          });
          // Success callback
          if (signScriveNotBankID && response.data) {
            if (
              response.data.signingProcess &&
              response.data.signingProcess.parties
            ) {
              response.data.signingProcess.parties.forEach(function (party) {
                if (party.api_delivery_url) {
                  var apiDeliveryUrl = party.api_delivery_url;
                  var signingUrl = constructSigningUrl(
                    response.data.serverAddress,
                    apiDeliveryUrl
                  );
                  setScriveDocumentId(response.data.newDocument.id);
                  setScriveUrl(signingUrl);
                }
              });
            }
          }
          // Handle success, like setting Scrive URL
        } catch (error) {
          // Error callback
          console.error('Error initiating Scrive sign process:', error);
        } finally {
          // Complete callback (executed after success or error) if swapped back to own information, clear the Scrive URL so button doesnt show
          setIsLoading(false);
          if (!signScriveNotBankID) {
            setScriveUrl(''); // Clear the Scrive URL
          }
        }
      } else {
        // Enables the next button when not initiating the process
        setData(true);
        setScriveDataFunction(null);
        setScriveUrl('');
      }
    };

    initiateScriveProcess();
  }, [signScriveNotBankID]);

  function constructSigningUrl(serverAddress, apiDeliveryUrl) {
    if (apiDeliveryUrl.startsWith('/')) {
      return serverAddress + apiDeliveryUrl;
    } else {
      return serverAddress + '/' + apiDeliveryUrl;
    }
  }

  const currentPlantInfo = () => {
    if (checkedValue === 0 && !isStepValid()) {
      setStepValidation(false);
    } else setStepValidation(true);
    handlevalidationFields();
    if (!loading && lowScore === undefined) {
      creditCheck();
    }
  };

  useEffect(() => {
    if (
      stepsList[currentStep + 1].selected &&
      stepsList[currentStep + 2].completed &&
      assignment.data.object_id !== ''
    ) {
      handlevalidationFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, stepsList]);

  // Update refs när state ändras
  useEffect(() => {
    checkedValueRef.current = checkedValue;
  }, [checkedValue]);

  useEffect(() => {
    scriveDataRef.current = scriveData;
  }, [scriveData]);

  useEffect(() => {
    signScriveNotBankIDRef.current = signScriveNotBankID;
  }, [signScriveNotBankID]);

  useEffect(() => {
    scriveDocumentIdRef.current = scriveDocumentId;
  }, [scriveDocumentId]);

  const checkScriveStatus = async () => {
    isCancelledRef.current = false;

    if (
      checkedValueRef.current === 0 ||
      intervalIdRef.current !== null ||
      scriveDataRef.current !== null
    ) {
      return;
    }

    const scriveServiceUrl = endpoints.scrivePwa;

    const checkStatus = async () => {
      if (isCancelledRef.current) {
        return;
      }

      if (
        checkedValueRef.current === 0 ||
        !scriveDocumentIdRef.current ||
        scriveDataRef.current ||
        !signScriveNotBankIDRef.current
      ) {
        if (intervalIdRef.current) {
          clearTimeout(intervalIdRef.current);
          intervalIdRef.current = null;
        }
        return;
      }

      try {
        const response = await axios.post(scriveServiceUrl, {
          action: 'getDocumentV2',
          documentId: scriveDocumentIdRef.current,
        });
        const signatoryStatus =
          response.data?.parties?.[1]?.experimental?.signatory_status;

        if (signatoryStatus === 'document_signed') {
          setTimeout(
            () => fetchDocumentData(scriveDocumentIdRef.current),
            5000
          );
          if (intervalIdRef.current) {
            clearTimeout(intervalIdRef.current);
            intervalIdRef.current = null;
          }
          setData(true);
          setDocumentSigned(true);
        } else {
          if (!isCancelledRef.current) {
            intervalIdRef.current = setTimeout(checkStatus, 1000);
          }
        }
      } catch (error) {
        console.error('Error checking document signed status:', error);
        if (
          checkedValueRef.current !== 0 &&
          signScriveNotBankIDRef.current &&
          !isCancelledRef.current
        ) {
          intervalIdRef.current = setTimeout(checkStatus, 1000);
        } else {
          if (intervalIdRef.current) {
            clearTimeout(intervalIdRef.current);
            intervalIdRef.current = null;
          }
        }
      }
    };

    intervalIdRef.current = setTimeout(checkStatus, 1000);
  };

  const fetchDocumentData = async (scriveDocumentId) => {
    const scriveServiceUrl = endpoints.scrivePwa;
    try {
      const response = await axios.post(scriveServiceUrl, {
        action: 'getDocumentFileData',
        documentId: scriveDocumentId,
      });

      const data = response.data.file_base64;
      setScriveDataFunction(data); // Store the fetched document data
    } catch (error) {
      console.error('Error fetching document data:', error);
    }
  };

  useEffect(() => {
    if (scriveData) {
      setData(true);
      setLoading(false);
    }
  }, [scriveData]);

  const handleStartDate = useCallback(
    (value) => {
      setAssignmentData({
        type: UPDATE_FACILITY_INFORMATION_CONTRACT_START,
        payload: value,
      });
    },
    [setAssignmentData]
  );
  useEffect(() => {
    const conditionToSendDate = () => {
      if (assignment.data.move_status === '2') {
        return startDateProxyOne;
      } else if (
        assignment.data.move_status === '1' &&
        assignment.data.proxy === '1'
      ) {
        return '';
      } else return facilityStartDate;
    };

    handleStartDate(conditionToSendDate());
  }, [
    assignment.data.move_status,
    assignment.data.proxy,
    facilityStartDate,
    handleStartDate,
    startDateProxyOne,
  ]);
  const handleSetIsChecked = (id) => {
    resetAreaInformation();
    handleStartDate(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setFacilityStartDate(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setStartDateProxyOne(
      assignment.data.move_status === '2'
        ? format(fourDaysFromNow, dateFormats.yearMonthDay)
        : startDate
    );
    setIsChecked(id);
    setAssignmentData({
      type: UPDATE_PROXY,
      payload: id === 1 ? '1' : '0',
    });
  };
  const resetAreaInformation = () => {
    handleAreaId('');
    handlePlantId('');
  };

  const showLowCreditScoreError = () => {
    return (
      <div className={styles.lowScoreWrap}>
        <Text error>
          Vi måste tyvärr avslå din ansökan om att teckna elavtal. Om du varit
          bosatt utomlands eller har spärrat ditt personnummer för
          kreditkontroll ber vi dig kontakta kundservice för att på så sätt
          kunna slutföra din ansökan om elavtal.
        </Text>
      </div>
    );
  };

  return (
    <div>
      <Text medium className={styles.textWrap}>
        Anläggningsuppgifter:
      </Text>
      <div className={styles.twoColumns}>
        {radioButtonData.map((item) =>
          item.id === 0 && checkedValue === 1 && documentSigned ? null : ( // Hide button with id 0
            <div key={item.id}>
              <div>
                <RadioButton
                  value={item.value}
                  label={
                    item.id === 1 && checkedValue === 1 ? (
                      isLoading ? (
                        <div className={styles.loaderContainer}>
                          <span className={styles.loaderText}>
                            Hämtar dokument
                          </span>
                          <LoaderCopy noMargin={true} />
                        </div>
                      ) : scriveData ? (
                        <span className={styles.successButton}>
                          Fullmakt signerad
                        </span>
                      ) : scriveUrl !== '' ? (
                        <>
                          <a
                            href={scriveUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.fullmaktButton}
                            onClick={() => {
                              checkScriveStatus();
                            }}
                          >
                            Öppna fullmakt & signera
                          </a>
                          <br></br>
                          <button
                            style={{
                              marginTop: '35px',
                              background: 'none',
                              borderRadius: '5px',
                              border: '2px solid #e4c1c0',
                              padding: '5px',
                            }}
                            onClick={() => {
                              setScriveUrl('');
                              setIsChecked(0);
                              clearTimeout(intervalIdRef.current);
                              intervalIdRef.current = null;
                              isCancelledRef.current = true;
                            }}
                          >
                            Fel uppgifter i fullmakten? Ladda in nytt
                            fullmaktsdokument
                          </button>
                        </>
                      ) : (
                        item.label
                      )
                    ) : (
                      item.label
                    )
                  }
                  name={item.name}
                  id={item.id}
                  isChecked={checkedValue === item.id}
                  onChange={() => handleSetIsChecked(item.id)}
                />
                {item.showDatepicker && checkedValue === 1 && (
                  <DatePickerComponent
                    required
                    label="Avtalsstart"
                    setAssignmentData={setAssignmentData}
                    checkedValue={checkedValue}
                    assignment={assignment}
                  />
                )}
              </div>
            </div>
          )
        )}
      </div>
      <ConditionalRender shouldRender={lowScore && checkedValue === 1}>
        {showLowCreditScoreError()}
      </ConditionalRender>

      <ConditionalRender shouldRender={checkedValue === 0}>
        <div className={styles.inputsWrap}>
          <div className={styles.twoColumns}>
            <Input
              label="Områdes-ID"
              placeholder="Områdes-ID..."
              required
              large
              onChange={(e) => handleAreaId(e.target.value)}
              error={showErrorBorder.areaId === false}
              outline
              value={assignment.data.net_area || ''}
            />
            <div />
          </div>
          <div className={styles.twoColumns}>
            <Input
              label="Anläggnings-ID"
              placeholder="Anläggnings-ID..."
              required
              large
              onChange={(e) => handlePlantId(e.target.value)}
              error={showErrorBorder.plantId === false}
              outline
              value={assignment.data.object_id || ''}
            />
            <DatePickerComponent
              required
              label="Avtalsstart"
              setAssignmentData={setAssignmentData}
              checkedValue={checkedValue}
              assignment={assignment}
            />
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender shouldRender={loading}>
        <Loader />
      </ConditionalRender>
      <ConditionalRender shouldRender={lowScore && checkedValue === 0}>
        {showLowCreditScoreError()}
      </ConditionalRender>
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={stepValidation && data !== false && !loading}
        handleClick={() => currentPlantInfo()}
        disabled={data === false || loading}
      />
    </div>
  );
};

export default FacilityInformation;
