import { Routes } from 'global/Routes';

export const pathLocationCheck = (url) =>
  url.lastIndexOf('/') === 0 ? url : url.substring(0, url.lastIndexOf('/'));

export const shortCodeOrganisation = (value) => {
  let organizationCode;
  switch (pathLocationCheck(value)) {
    /*case Routes.PERSONAL_BEST:
      organizationCode = 'Pb';
      break;*/
    /*case Routes.MEDIA_MARKT:
      organizationCode = 'Mm';
      break;*/
    case Routes.EVENT:
      organizationCode = 'Ev';
      break;
    case Routes.TIMPRIS:
      organizationCode = 'Ti';
      break;
    case Routes.PCE:
      organizationCode = 'Pce';
      break;
    case Routes.EL:
      organizationCode = 'El';
      break;
    case Routes.ELON:
      organizationCode = 'Elon';
      break;
    default:
  }
  return organizationCode;
};

export const organizationCode = (value) => {
  let organizationCode;
  switch (pathLocationCheck(value)) {
    /*case Routes.PERSONAL_BEST:
      organizationCode = 0;
      break;*/
    /*case Routes.MEDIA_MARKT:
      organizationCode = 2;
      break;*/
    case Routes.EVENT:
      organizationCode = 1;
      break;
    case Routes.TIMPRIS:
      organizationCode = 3;
      break;
    case Routes.PCE:
      organizationCode = 22;
      break;
    case Routes.EL:
      organizationCode = 22;
      break;
    case Routes.ELON:
      organizationCode = 57;
      break;
    default:
  }
  return organizationCode;
};
