import React from 'react';
import { useLocation } from 'react-router';
import classnames from 'classnames';

import { Routes } from 'global/Routes';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';

import { Perks } from 'containers/Perks';
import { InfoBlock } from 'containers/InfoBlock';
import { Brands } from 'containers/Brands';
import { AreaChange } from 'containers/AreaChange';
import { AgreementsVideo } from 'containers/AgreementsVideo';

import { Button } from 'components/Buttons';
import { ReadMore } from 'components/Buttons';
import { Image } from 'components/Image';
import purchasePriceImg from 'global/images/purchase-price-img.jpg';
import styles from './styles.module.scss';

export const Agreements = ({ data, loading, error, handleOpenModal }) => {
  const location = useLocation();
  return (
    <div className={classnames(styles.container)}>
      <div className={styles.agreementsWrap}>
        <div className={styles.agreementImage}>
          <Image imageUrl={purchasePriceImg} alt="agreement image" />
        </div>
        <div className={styles.infoSection}>
          <Perks data={data} loading={loading} error={error} />
          <InfoBlock />
          <Brands />
          <AreaChange />
          <Button
            primaryButton
            text={
              pathLocationCheck(location.pathname) === Routes.LEADS
                ? 'Intresseanmälan'
                : 'TECKNA DETTA AVTAL'
            }
            centerAlign
            leadsStylesButton={
              pathLocationCheck(location.pathname) === Routes.LEADS
            }
            onClick={() => {
              handleOpenModal();
            }}
          />
        </div>
      </div>
      <ReadMore />
      <AgreementsVideo />
    </div>
  );
};
