export const postalCodeValidation = (postalCode) => {
  if (postalCode.length === 5 && /^-?\d+$/.test(postalCode)) {
    return true;
  }
  if (
    postalCode.length === 7 &&
    (postalCode.substring(0, 2) === 's-' || postalCode.substring(0, 2) === 'S-')
  ) {
    return true;
  }
  return false;
};

export const handleOnFocus = (name, func) => {
  func((prevState) => ({ ...prevState, [name]: undefined }));
};

export const lengthValidation = (value) => {
  return value && value.length > 0;
};

export const validateEmail = (email) => {
  /* eslint-disable */
  return (
    /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i.test(email) &&
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      email
    )
  );
};

export const validatePhone = (phone) => {
  const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  return !(!phone.length > 9 || regex.test(phone) === false);
};
