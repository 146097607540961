import React from 'react';
import { Text } from 'components/Text';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const Input = ({
  type,
  placeholder,
  value,
  id,
  onChange,
  error,
  label,
  required,
  large,
  maxLength,
  onFocus,
  name,
  outline,
  pattern,
}) => {
  return (
    <div className={styles.inputWrap}>
      <Text medium>
        {label}
        {required && <span className={styles.required}>*</span>}
      </Text>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        id={id}
        maxLength={maxLength}
        className={classnames(
          styles.areaInput,
          error && styles.error,
          large && styles.areaInputLarge,
          outline && styles.outline
        )}
        onChange={onChange}
        onFocus={onFocus}
        name={name}
        pattern={pattern}
      />
    </div>
  );
};
