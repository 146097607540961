import React, { useState, useCallback, useEffect, useContext } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { Input } from 'components/Input';
import { Text } from 'components/Text';
import { Loader } from 'components/Loader';
import BottomButtons from '../BottomButtons';
import ConditionalRender from 'components/ConditionalRender';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';

import {
  UPDATE_PERSONAL_NUMBER,
  UPDATE_USER_INFORMATION,
  USER_EXISTING,
  UPDATE_FACILITY_INFORMATION_AREA_ID,
  CHECK_ADDRESS,
  SET_INVOICE_ADDRESS,
  SET_BILLING_ADDRESS,
} from 'utils/reducerConstants';
import { existingAddress } from 'utils/constants';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { endpoints } from 'global/endpoints';
import { Routes } from 'global/Routes';

import {
  validation,
  charactersValidation,
} from 'utils/personalNumberValidation';
import { ageOverLimit } from 'utils/calculateuserAge';

import styles from './styles.module.scss';

const SecurityNumber = ({
  assignment,
  setAssignmentData,
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  makeCallFromLocalStorage,
  setIsOnline,
  isOpen,
  setPrivateSocIdFunction,
}) => {
  const [validPersonalNumber, setIsPersonalNumberValid] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const personalNumberLength = 11;
  const { soc_id } = assignment.data;
  const { userExisting } = assignment;
  const location = useLocation();
  const eventRoute = pathLocationCheck(location.pathname) === Routes.EVENT;
  const [finishedCallsWhenGoOnline, setFinishedCallsWhenGoOnline] = useState(
    false
  );

  const {
    setValidation,
    setFirstStepValidation,
    firstStepValidation,
    validation: onlineValidation,
    setCompletedFirstStep,
    firstStepCompleted,
  } = useContext(GlobalContextContainer);

  const getNetArea = useCallback(
    (zipCode) => {
      if (navigator.onLine) {
        axios
          .get(`${endpoints.netArea}?zip_code=${zipCode}`)
          .then((res) => {
            setLoading(false);
            setAssignmentData({
              type: UPDATE_FACILITY_INFORMATION_AREA_ID,
              payload: res.data.name,
            });
            setFirstStepValidation(true);
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      }
    },
    [setAssignmentData, setFirstStepValidation]
  );

  const getUserData = useCallback(() => {
    let endpoint = `${endpoints.businessCheck}?personal_number=${soc_id}`;
    if (navigator.onLine) {
      setLoading(true);
      axios
        .get(endpoint)
        .then((res) => {
          setLoading(true);
          if (res.data.zip_code.length > 0) {
            getNetArea(res.data.zip_code);
          }
          setAssignmentData({
            type: UPDATE_USER_INFORMATION,
            payload: res.data,
          });
          setAssignmentData({
            type: SET_BILLING_ADDRESS,
            payload: res.data,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [getNetArea, setAssignmentData, soc_id]);

  const existingCheck = useCallback(() => {
    if (navigator.onLine) {
      setLoading(true);
      axios
        .get(`${endpoints.checkUserExisting}?personal_number=${soc_id}`)
        .then((res) => {
          setAssignmentData({
            type: USER_EXISTING,
            payload: res.data,
          });
          if (res.data.customerId.length > 0 && eventRoute) {
            getUserData();
          }
          if (res.data.customerId === false && eventRoute) {
            !ageOverLimit(soc_id)
              ? getUserData()
              : setFirstStepValidation(false);
          }

          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 503) {
            setFirstStepValidation(true);
            setValidation(0);
          } else {
            console.log(err);
          }
          setLoading(false);
        });
    }
  }, [
    setAssignmentData,
    eventRoute,
    getUserData,
    setFirstStepValidation,
    soc_id,
    setValidation,
  ]);

  const handleChange = (target) => {
    setPrivateSocIdFunction(target);

    if (firstStepCompleted) {
      setCompletedFirstStep(false);
    }
    if (firstStepValidation) {
      setFirstStepValidation(false);
    }
    setIsPersonalNumberValid(validation(target));
    if (firstStepValidation && navigator.onLine) {
      setAssignmentData({
        type: USER_EXISTING,
        payload: {},
      });
      setAssignmentData({
        type: CHECK_ADDRESS,
        payload: existingAddress.initial,
      });
    }
    setAssignmentData({
      type: UPDATE_PERSONAL_NUMBER,
      payload: { soc_id: charactersValidation(target) },
    });

    setAssignmentData({
      type: SET_INVOICE_ADDRESS,
      payload: {
        ...assignment.data,
        address2: undefined,
        zip_code2: undefined,
        city2: undefined,
      },
    });
  };

  const handleClick = () => {
    if (soc_id.length === 0) {
      setIsPersonalNumberValid(false);
    }
    if (firstStepValidation) {
      setCompletedFirstStep(true);
    }
  };
  useEffect(() => {
    if (!isOpen) {
      setIsPersonalNumberValid(undefined);
    }
  }, [setIsPersonalNumberValid, isOpen]);

  useEffect(() => {
    if (soc_id.length === personalNumberLength) {
      setIsPersonalNumberValid(validation(soc_id));
    }
    if (soc_id.length < personalNumberLength) {
      setAssignmentData({
        type: USER_EXISTING,
        payload: {},
      });
    }
    if (
      navigator.onLine &&
      validPersonalNumber & !firstStepValidation &&
      !eventRoute &&
      !finishedCallsWhenGoOnline
    ) {
      existingCheck();
      getUserData();
    }
    if (validPersonalNumber === false && navigator.onLine) {
      setFirstStepValidation(false);
    }
    if (!navigator.onLine) {
      if (validPersonalNumber) {
        setFirstStepValidation(true);
      } else setFirstStepValidation(false);
    }
  }, [
    soc_id,
    soc_id.length,
    setAssignmentData,
    validPersonalNumber,
    location,
    eventRoute,
    firstStepValidation,
    onlineValidation,
    existingCheck,
    getUserData,
    setFirstStepValidation,
    finishedCallsWhenGoOnline,
  ]);
  useEffect(() => {
    if (
      userExisting &&
      Object.keys(userExisting).length > 0 &&
      soc_id.length === personalNumberLength &&
      !eventRoute
    ) {
      setFirstStepValidation(true);
    }
  }, [
    currentStep,
    setCurrentStep,
    stepsList,
    userExisting,
    location,
    soc_id,
    eventRoute,
    setFirstStepValidation,
  ]);

  useEffect(() => {
    if (
      userExisting &&
      Object.keys(userExisting).length > 0 &&
      soc_id.length === personalNumberLength &&
      (userExisting.customerId.length > 0 ||
        (userExisting.customerId === false && !ageOverLimit(soc_id))) &&
      eventRoute
    ) {
      setFirstStepValidation(true);
    }
  }, [
    currentStep,
    setCurrentStep,
    stepsList,
    userExisting,
    location,
    soc_id,
    eventRoute,
    setFirstStepValidation,
  ]);

  const basicEventCheck =
    eventRoute && navigator.onLine && soc_id.length === personalNumberLength;

  const isUserNotExisting =
    basicEventCheck &&
    Object.keys(userExisting).length > 0 &&
    userExisting.customerId === false;

  useEffect(() => {
    if (
      basicEventCheck &&
      validPersonalNumber &&
      !firstStepCompleted &&
      !finishedCallsWhenGoOnline
    ) {
      existingCheck();
    }
  }, [
    validPersonalNumber,
    firstStepCompleted,
    basicEventCheck,
    finishedCallsWhenGoOnline,
    existingCheck,
  ]);

  const eventRouteValidation = () =>
    onlineValidation === 0 &&
    eventRoute &&
    soc_id.length === personalNumberLength;

  window.ononline = () => {
    makeCallFromLocalStorage();
    if (!isOpen) {
      setValidation(1);
    }
    setIsOnline(true);
    setFirstStepValidation(false);
    setFinishedCallsWhenGoOnline(
      eventRoute &&
        soc_id.length === personalNumberLength &&
        onlineValidation === 0
    );
    if (
      soc_id.length === personalNumberLength &&
      !eventRoute &&
      finishedCallsWhenGoOnline
    ) {
      existingCheck();
      getUserData();
    }
    if (
      eventRoute &&
      soc_id.length === personalNumberLength &&
      onlineValidation === 0 &&
      finishedCallsWhenGoOnline
    ) {
      existingCheck();
    }
    if (
      eventRouteValidation() &&
      Object.keys(userExisting).length > 0 &&
      userExisting.customerId.length > 0
    ) {
      getUserData();
    }
    if (
      eventRouteValidation() &&
      Object.keys(userExisting).length > 0 &&
      userExisting.customerId === false &&
      !ageOverLimit(soc_id)
    ) {
      getUserData();
    }
    if (
      eventRouteValidation() &&
      Object.keys(userExisting).length > 0 &&
      userExisting.customerId === false &&
      ageOverLimit(soc_id)
    ) {
      setFirstStepValidation(false);
    }
  };
  return (
    <div>
      <div className={styles.securityNumberWrap}>
        <Text>
          Skriv in ditt personnummer, så hämtas dina uppgifter automatiskt.
          (ÅÅMMDD-XXXX)
        </Text>
        <div className={styles.inputWrap}>
          <Input
            large
            onChange={(e) => handleChange(e.target.value)}
            maxLength={12}
            value={soc_id}
            error={validPersonalNumber === false}
            outline
          />
          <ConditionalRender
            shouldRender={userExisting && userExisting.showMessage && !loading}
          >
            <Text medium className={styles.existingUserMessage}>
              {userExisting && userExisting.message}
            </Text>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={
              eventRoute && isUserNotExisting && ageOverLimit(soc_id)
            }
          >
            <Text medium className={styles.existingUserMessage}>
              Ej möjligt att teckna avtal, vänligen be kunden kontakta
              kundservice på 0771-456150.
            </Text>
          </ConditionalRender>
          <ConditionalRender
            shouldRender={isUserNotExisting && !ageOverLimit(soc_id)}
          >
            <Text medium className={styles.existingUserMessage}>
              I samband med att du fyller i dina personuppgifter kommer en
              kreditupplysning att tas
            </Text>
          </ConditionalRender>
        </div>
        <ConditionalRender shouldRender={loading}>
          <Loader />
        </ConditionalRender>
        <div className={styles.bottomTextWrap}>
          <Text medium>
            Du är 30 sekunder från att teckna ett billigt och grönt elavtal.
          </Text>
        </div>
      </div>
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={firstStepValidation}
        handleClick={handleClick}
        disabled={!firstStepValidation}
      />
    </div>
  );
};

export default SecurityNumber;
