import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const ToggleSwitch = ({
  isChecked,
  handleToggle,
  title,
  disabled,
  id,
  processing,
}) => (
  <div className={styles.toggleSwitch}>
    <input
      checked={isChecked}
      onChange={handleToggle}
      className={styles.switchCheckBox}
      id={id || 'toggleSwitch'}
      type="checkbox"
      disabled={disabled}
    />
    <label
      className={classnames(
        styles.switchLabel,
        isChecked && styles.active,
        processing && styles.processing,
        disabled && styles.disabled
      )}
      htmlFor={id || 'toggleSwitch'}
      title={title}
    >
      <span className={styles.switchButton} />

      {isChecked ? (
        <FontAwesomeIcon icon={faCheck} className={styles.switchOnIcon} />
      ) : (
        <FontAwesomeIcon icon={faTimes} className={styles.switchOffIcon} />
      )}
    </label>
  </div>
);

export default ToggleSwitch;
