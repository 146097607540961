import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import { AssignmentReducer, DefaultAssignment } from 'utils/assignmentReducer';
import {
  RESET_MODAL,
  SELECTED_STORE,
  UPDATE_USER_INFORMATION,
} from 'utils/reducerConstants';
import { handleOnFocus } from 'utils/userInformationValidations';
import { dateFormats } from 'utils/constants';
import {
  organizationCode,
  shortCodeOrganisation,
} from 'utils/shortCodeOrganisation';
import { setAssignmentDataHelper } from 'utils/noStepperModalHelpers';

import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';
import { Input } from 'components/Input';
import { Button } from 'components/Buttons';
import { NoStepperDropdown } from 'components/NoStepperDropdown';
import { RadioButton } from 'components/Buttons';
import { Text } from 'components/Text';
import ConditionalRender from 'components/ConditionalRender';
import { Loader } from 'components/Loader';

import { useLocation } from 'react-router';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import { endpoints } from 'global/endpoints';
import { toast } from 'react-toastify';

export const NoStepperModalContent = ({
  isOpen,
  isMinimized,
  makeCallFromLocalStorage,
  setIsOpen,
}) => {
  const [assignment, setAssignmentData] = useReducer(
    AssignmentReducer,
    DefaultAssignment
  );
  const [validations, setValidations] = useState({});
  const {
    localStorageIsFull,
    setLocalStorageIsFull,
    storeOptions,
    loading,
    setLoading,
  } = useContext(GlobalContextContainer);

  const location = useLocation();

  const data = assignment.data;

  const handleValidations = () => {
    const inputNames = [
      'first_name',
      'last_name',
      'store_id',
      'cell_phone',
      'agent_id',
    ];
    let validationState = {};
    inputNames.forEach((input) => {
      validationState = {
        ...validationState,
        [input]: data[input].trim() !== '',
      };
    });
    setValidations(validationState);

    return validationState;
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    document.body.style.overflow = 'auto';
  };

  const handleChange = (e) => {
    if (setAssignmentDataHelper(e.target.name) === UPDATE_USER_INFORMATION) {
      setAssignmentData({
        type: UPDATE_USER_INFORMATION,
        payload: { ...data, [e.target.name]: e.target.value },
      });
    } else {
      setAssignmentData({
        type: setAssignmentDataHelper(e.target.name),
        payload: e.target.value,
      });
    }
  };

  const handleSelect = (e) => {
    setValidations({ ...validations, store_id: true });
    setAssignmentData({
      type: SELECTED_STORE,
      payload: e.target.value,
    });
  };

  const handleCreateContract = () => {
    const dataToSend = {
      ...assignment.data,
      created_at: format(new Date(), dateFormats.yearMonthDayTime),
      organization: organizationCode(location.pathname),
      mode: navigator.onLine ? '1' : '0',
    };
    axios
      .post(endpoints.assignLeads, dataToSend)
      .then(() => {
        setLoading(false);
        toast.success('Nu är dina uppgifter Sparade!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        handleCloseModal();
      })
      .catch((err) => {
        toast.error(`${err.response.data.data}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        setLoading(false);
      });
  };

  const addContractToLocalStorage = () => {
    const dataToSend = {
      ...assignment.data,
      created_at: format(new Date(), dateFormats.yearMonthDayTime),
      organization: organizationCode(location.pathname),
      mode: navigator.onLine ? '1' : '0',
    };

    const allLeads =
      JSON.parse(
        localStorage.getItem(`leads${shortCodeOrganisation(location.pathname)}`)
      ) || [];
    allLeads.push(dataToSend);
    if (checkAvailable() === true) {
      // available
      localStorage.setItem(
        `leads${shortCodeOrganisation(location.pathname)}`,
        JSON.stringify(allLeads)
      );
    } else {
      // unavailable`
      setLocalStorageIsFull(true);
    }
  };

  const checkAvailable = () => {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmitButton = () => {
    const validationState = handleValidations();
    const checkValidation = Object.values(validationState).some(
      (value) => !value
    );
    if (!checkValidation) {
      if (navigator.onLine) {
        handleCreateContract();
      } else {
        handleCloseModal();
        toast.success('Uppgifter är sparade OFFLINE!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        });
        addContractToLocalStorage();
        makeCallFromLocalStorage();
      }
      setLoading(true);
      setAssignmentData({
        type: RESET_MODAL,
      });
    }
  };

  useEffect(() => {
    if (!isOpen && !isMinimized) {
      setAssignmentData({
        type: RESET_MODAL,
      });
      setValidations({});
    }
  }, [isMinimized, isOpen]);

  return (
    <div className={styles.root}>
      <div className={styles.twoColumns}>
        <Input
          required={true}
          label="Förnamn"
          placeholder="Förnamn..."
          large
          onChange={handleChange}
          name="first_name"
          value={data.first_name}
          onFocus={(e) => handleOnFocus(e.target.name, setValidations)}
          error={validations.first_name === false}
          outline
        />

        <Input
          required={true}
          label="Efternamn"
          placeholder="Efternamn"
          large
          onChange={handleChange}
          name="last_name"
          value={data.last_name}
          onFocus={(e) => handleOnFocus(e.target.name, setValidations)}
          error={validations.last_name === false}
          outline
        />
      </div>
      <div className={styles.twoColumns}>
        <NoStepperDropdown
          storeOptions={
            navigator.onLine && storeOptions?.lenght && storeOptions
          }
          center
          onSelect={handleSelect}
          showErrorMessage={validations.store_id === false}
          value={data.store_id}
          onFocus={(e) => handleOnFocus(e.target.name, setValidations)}
          label={'Välj butik'}
          required={true}
          //if we need the key as the selected value
          selected="key"
        />
        <div className={styles.twoColumns}>
          <Input
            label="Telefonnummer"
            required
            placeholder="Telefonnummer..."
            name="cell_phone"
            large
            type="number"
            onChange={handleChange}
            onFocus={(e) => handleOnFocus(e.target.name, setValidations)}
            outline
            value={data.cell_phone}
            error={validations.cell_phone === false}
          />
          <Input
            label="Sälj-id"
            placeholder="Sälj-id..."
            required
            name="agent_id"
            large
            type="number"
            onChange={handleChange}
            outline
            value={data.agent_id}
            onFocus={(e) => handleOnFocus(e.target.name, setValidations)}
            error={validations.agent_id === false}
            pattern="^[\d]+$"
          />
        </div>
      </div>
      <div className={styles.twoColumns}>
        <div>
          <Text medium>Välj alternativ?</Text>
          <div className={styles.twoColumnsRadioButton}>
            <RadioButton
              id="villa"
              value="0"
              name="building_type"
              label="Villa"
              isChecked={data.building_type === '0'}
              onChange={handleChange}
            />
            <RadioButton
              id="lägenhet"
              value="1"
              name="building_type"
              label="Lägenhet"
              isChecked={data.building_type === '1'}
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <Text medium>Är det du som står på elavtalet?</Text>
          <div className={styles.twoColumnsRadioButton}>
            <RadioButton
              id="contractNo"
              value="0"
              name="creator_confirmation"
              label="Nej"
              isChecked={data.creator_confirmation === '0'}
              onChange={handleChange}
            />
            <RadioButton
              id="contractYes"
              value="1"
              name="creator_confirmation"
              label="Ja"
              isChecked={data.creator_confirmation === '1'}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className={styles.messages}>
        <ConditionalRender shouldRender={localStorageIsFull}>
          <Text medium error className={styles.localStorageError}>
            Local storage is full.
          </Text>
        </ConditionalRender>
        <ConditionalRender shouldRender={loading && navigator.onLine}>
          <Loader />
        </ConditionalRender>
      </div>
      <div className={styles.button}>
        <Button submitButton text="OK" onClick={() => handleSubmitButton()} />
      </div>
    </div>
  );
};
