import React, { useState } from 'react';
import '../styles.css';
import { DottedArrowUp, DottedArrowDown } from './DottedArrows';
import { CalculatePriceBox } from './CalculatePriceBox';

export const Timpris = ({ isSelected, toggleSelect, areaCode }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div className={`elavtal-wrapper ${isSelected ? 'selected' : ''}`}>
      <div className="elavtal-box">
        <h1 className="_textmain">RÖRLIGT PRIS - ELON KAMPANJ</h1>
        <p
          className={`${
            isVisible ? 'secret-boxtext-elon-border' : ''
          } _textmain boxtext-elon`}
        >
          Perfekt för dig som uppskattar det rörliga prisets fördelar med lågt
          pris över tid
        </p>
        <div className={`hidden-elavtal-content ${isVisible ? 'visible' : ''}`}>
          <CalculatePriceBox
            areaCode={areaCode}
            type={'timpris'}
            monthly={null}
          />
        </div>
        <button className="toggle-button" onClick={toggleVisibility}>
          {isVisible ? <DottedArrowUp /> : <DottedArrowDown />}
        </button>
      </div>
      <p className="choosen_agree_checkbox_text">
        {isSelected ? 'Valt elavtal' : 'Välj elavtal'}
      </p>
      <input
        type="checkbox"
        checked={isSelected}
        //onChange={toggleSelect}
        readOnly
        className="elavtal-option"
      />
    </div>
  );
};

export default Timpris;
