import React from 'react';
import { Text } from 'components/Text';
import styles from './styles.module.scss';

export const MinimizedModal = ({
  closeModal,
  handleMaximize,
  setIsMinimizes,
}) => {
  const handleMaximizeModal = () => {
    handleMaximize('visible');
  };

  const handleCloseModal = () => {
    closeModal(false);
    setIsMinimizes(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <div className={styles.minimizedModal}>
      <div className={styles.openButton} onClick={handleMaximizeModal}>
        <Text white medium>
          + Öppna
        </Text>
      </div>
      <div className={styles.closeButton} onClick={handleCloseModal}>
        <Text white medium>
          × Stäng
        </Text>
      </div>
      <div className={styles.closeButton}></div>
    </div>
  );
};
