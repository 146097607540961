import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';
import styles from './styles.module.scss';

function BankIdQRComponent({
  qrUrl,
  orderRef,
  handleStatusChange,
  onError,
  statusMessage,
}) {
  const [currentQrUrl, setCurrentQrUrl] = useState(qrUrl);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const statusCheckTimeoutRef = useRef(null);

  // Update the QR code every second with a new timestamp
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newUrl = `${qrUrl}&dummy=${new Date().getTime()}`; // Append a time-based query to the URL
      setCurrentQrUrl(newUrl); // Update the current QR URL
    }, 1000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [qrUrl]);

  const checkSignStatus = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/contract/QRsignCheck`,
        { orderRef },
        { withCredentials: true }
      );
      //console.log('Response Data:', response.data); // Log response data
      if (response.data.status === 'complete') {
        clearTimeout(statusCheckTimeoutRef.current);
        handleStatusChange('complete', response.data.message);
      } else if (response.data.status === 'failed') {
        clearTimeout(statusCheckTimeoutRef.current);
        handleStatusChange('failed', response.data.message);
      } else if (response.data.status === 'pending') {
        // Handle pending status specifically if needed
        // console.log('Status is pending:', response.data.message);
        handleStatusChange('pending', response.data.message);
        statusCheckTimeoutRef.current = setTimeout(checkSignStatus, 1000);
      } else {
        statusCheckTimeoutRef.current = setTimeout(checkSignStatus, 1000);
      }
    } catch (err) {
      console.error('Error checking sign status:', err);
      setIsError(true);
      setError(`Failed to check sign status: ${err.message}`);
      onError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (qrUrl && orderRef) {
      statusCheckTimeoutRef.current = setTimeout(checkSignStatus, 1000);
    }
    return () => clearTimeout(statusCheckTimeoutRef.current);
  }, [qrUrl, orderRef]);

  if (isError) {
    return (
      <div className={styles.error}>
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className={styles.centeredContainer}>
      <div className={styles.bankIdQrWrapper}>
        {currentQrUrl && (
          <>
            <h1>Skanna QR-koden med BankID appen</h1>
            <QRCode value={currentQrUrl} size={200} level="L" />
            {statusMessage && <p> {statusMessage}</p>}
          </>
        )}
      </div>
    </div>
  );
}

export default BankIdQRComponent;
