// personal number

export const UPDATE_PERSONAL_NUMBER = 'UPDATE_PERSONAL_NUMBER';
export const USER_EXISTING = 'USER_EXISTING';

// user informations
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const UPDATE_SAME_ADDRESS = 'UPDATE_SAME_ADDRESS';
export const UPDATE_MOVE_STATUS = 'UPDATE_MOVE_STATUS';
export const CHECK_ADDRESS = 'CHECK_ADDRESS';

// billing address
export const SET_INVOICE_ADDRESS = 'SET_INVOICE_ADDRESS';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';

// Step three - Facility Information
export const UPDATE_FACILITY_INFORMATION_AREA_ID =
  'UPDATE_FACILITY_INFORMATION_AREA_ID';
export const UPDATE_FACILITY_INFORMATION_PLANT_ID =
  'UPDATE_FACILITY_INFORMATION_PLANT_ID';
export const UPDATE_FACILITY_INFORMATION_CONTRACT_START =
  'UPDATE_FACILITY_INFORMATION_CONTRACT_START';
export const UPDATE_PROXY = 'UPDATE_PROXY';

// step four - Store
export const SELECTED_STORE = 'SELECTED_STORE';

// Step five - Contract
export const AGENT_ID = 'AGENT_ID';
export const EMAIL = 'EMAIL';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const UPLOADED_PHOTO = 'UPLOADED_PHOTO';

export const TOGGLE_BUTTON = 'TOGGLE_BUTTON';
export const UPLOADED_IMAGE_NAME = 'UPLOADED_IMAGE_NAME';
export const AGREEMENT_OPTIONS = 'AGREEMENT_OPTIONS';
export const RESET_MODAL = 'RESET_MODAL';
export const UPDATE_AGENT_ID = 'UPDATE_AGENT_ID';

export const UPDATE_BANK_ID = 'UPDATE_BANK_ID';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const UPDATE_ACCOMODATION = 'UPDATE_ACCOMODATION';
export const UPDATE_CONTRACT_CHECK = 'UPDATE_CONTRACT_CHECK';
