import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const BoxWrapper = ({ children, light, className }) => (
  <div
    className={classnames(light && styles.wrapLight, styles.wrap, className)}
  >
    {children}
  </div>
);
