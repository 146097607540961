import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { addDays } from 'date-fns';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';

export const dateFormats = {
  yearMonthDay: 'yyyy-MM-dd',
  dayMonthYear: 'dd-MM-yyyy',
  time: 'HH:mm',
  yearMonthDayTime: 'yyyy/MM/dd HH:mm:ss',
};

export const fourDaysFromNow = addDays(new Date(), 4);
// currencies

export const currencies = {
  kWh: 'kWh',
  unitPrice: 'öre/kWh',
  kr: 'kr',
  ore: 'öre',
  man: 'mån',
  krMan: 'kr/mån',
};

// steps names

export const stepsNames = {
  securityNumber: 'securityNumber',
  userInformation: 'userInformation',
  facilityInformation: 'facilityInformation',
  store: 'store',
  contract: 'contract',
  billingAddress: 'billingAddress',
  success: 'success',
};

// billing address
export const billingAddress = {
  name: 'billingAddress',
  icon: faEnvelope,
  completed: false,
  selected: false,
};

// modal facilities

export const currentFacilityInfo =
  'Jag godkänner att Telinet får fullmakt att inhämta anläggningsuppgifter och information om mitt elnätsavtal samt information från mitt nuvarande elhandelsbolag om nästa möjligt startdatum för ett nytt elavtal samt säga upp mitt befintliga elavtal.';
export const newFacilityInfo =
  'Jag har mina anläggningsuppgifter och vill fylla i själv';

// modal agreements

/*export const agreementOptions = [
  {
    name: 'agreementOptions',
    id: 'signedAggrement',
    label:
      'Jag förstår att jag har tecknat avtal om leverans av el med Telinet Energi AB',
    value: false,
  },
  {
    name: 'agreementOptions',
    id: 'refound',
    label:
      'Jag förstår att jag blir återbetalningsskyldig för presentkortet a 500kr om jag inte kan starta som kund hos Telinet Energi AB',
    value: false,
    label_2:
      'Jag förstår att presentkortet à 300 kr ej betalas ut, om jag inte kan starta som kund hos Telinet Energi AB',
  },
  {
    name: 'agreementOptions',
    id: 'change',
    label:
      'Jag förstår att detta innebär ett byte av elleverantör och att jag behåller min nuvarande nätleverantör',
    value: false,
  },
];*/

export const agreementOptions =
  pathLocationCheck(window.location.pathname) === Routes.TIMPRIS || Routes.PCE
    ? [
        {
          name: 'agreementOptions',
          id: 'signedAggrement',
          label:
            'Jag förstår att jag har tecknat avtal om leverans av el med Telinet Energi AB',
          value: false,
        },
        {
          name: 'agreementOptions',
          id: 'change',
          label:
            'Jag förstår att detta innebär ett byte av elleverantör och att jag behåller min nuvarande nätleverantör',
          value: false,
        },
      ]
    : [
        {
          name: 'agreementOptions',
          id: 'signedAggrement',
          label:
            'Jag förstår att jag har tecknat avtal om leverans av el med Telinet Energi AB',
          value: false,
        },
        {
          name: 'agreementOptions',
          id: 'refound',
          label:
            'Jag förstår att jag blir återbetalningsskyldig för presentkortet a 500kr om jag inte kan starta som kund hos Telinet Energi AB',
          value: false,
          label_2:
            'Jag förstår att presentkortet à 300 kr ej betalas ut, om jag inte kan starta som kund hos Telinet Energi AB',
        },
        {
          name: 'agreementOptions',
          id: 'change',
          label:
            'Jag förstår att detta innebär ett byte av elleverantör och att jag behåller min nuvarande nätleverantör',
          value: false,
        },
      ];

export const existingAddress = {
  existing: 'existing',
  nonExisting: 'non-existing',
  initial: 'initial',
};
