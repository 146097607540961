import React from 'react';

import { BoxWrapper } from 'components/BoxWrapper';
import { Chart } from 'components/Chart';

import styles from './styles.module.scss';
import { Title } from 'components/Title';
import { Text } from 'components/Text';
import { Spinner } from 'components/Spinner';

export const History = ({ apiConnection, loading, isTimprisContract }) => (
  <div className={styles.container}>
    <div className={styles.historyWrap}>
      <BoxWrapper className={styles.historyDescription}>
        <Title title="Hur fungerar elpriset?" large light />
        <Text mediumSecondary white>
          <p className={styles.historyParagraph}>
            Så här: Nord Pool är nordens gemensamma elbörs. Den består av en
            spothandel och en terminshandel. Spotpris betyder dagligt pris och
            sätts utifrån tillgång och efterfrågan, en dag i förväg. Priset
            ändras timme för timme men i Sverige handlas den mesta elen på ett
            genomsnittspris för hela månaden. Fastprisavtal köps utifrån
            standardiserade kontrakt – terminer – där man har rätt att köpa
            eller sälja en viss mängd energi över en viss period till ett visst
            pris.
          </p>
        </Text>
        <Text mediumSecondary white>
          <p className={styles.historyParagraph}>
            Men det är mycket som påverkar förstås: världsmarknadspriset på
            olja, dollarkursen, politiska beslut och ja, väder och vind,
            såklart.
          </p>
        </Text>
      </BoxWrapper>
      <BoxWrapper className={styles.historyChart}>
        <Title title="Historiskt elpris" large light />
        {loading ? (
          <Spinner />
        ) : (
          <Chart
            apiConnection={apiConnection}
            isTimprisContract={isTimprisContract}
          />
        )}
      </BoxWrapper>
    </div>
  </div>
);
