export const questions = [
  {
    text:
      'För varje grad du sänker din inomhustemperatur minskar du din elkonsumtion med?',
    answers: ['5%', '1%', '10%', '15%'],
    correctAnswer: '5%',
  },
  {
    text: 'Vilka av nedan räknas som förnybara energikällor?',
    answers: [
      'Solenergi, kärnkraft, Vindkraft',
      'Vätgas, Olja, Vindkraft',
      'Solenergi, vindkraft, vatten och vågkraft',
      'Solenergi, Olja och Kolkraft',
    ],
    correctAnswer: 'Solenergi, vindkraft, vatten och vågkraft',
  },
  {
    text: 'Vad kallas det fenomen där ozonlagret i atmosfären tunnas ut?',
    answers: [
      'Koldioxidavmattning',
      'Jordförstöring',
      'Klimatförändring',
      'Ozonnedbrytning',
    ],
    correctAnswer: 'Ozonnedbrytning',
  },
  {
    text: 'Vilket land är världens största producent av förnybar energi?',
    answers: ['USA', 'Kina', 'Tyskland', 'Brasilien'],
    correctAnswer: 'Kina',
  },
  {
    text:
      'Vilken gas är den huvudsakliga orsaken till den globala uppvärmningen?',
    answers: ['Kväveoxid', 'Metan', 'Ozon', 'Väte'],
    correctAnswer: 'Metan',
  },
  {
    text: 'Vad är den främsta källan till luftförorening i städer?',
    answers: [
      'Industriutsläpp',
      'Bilavgaser',
      'Eldning med fossila bränslen',
      'Rökgaser från kraftverk',
    ],
    correctAnswer: 'Bilavgaser',
  },
  {
    text: 'Vilket land har flest solcellsanläggningar per capita?',
    answers: ['Tyskland', 'Kina', 'Japan', 'Sverige'],
    correctAnswer: 'Tyskland',
  },
  {
    text: 'Vad är enheten för elektrisk ström?',
    answers: ['Ohm', 'Ampere', 'Volt', 'Watt'],
    correctAnswer: 'Ampere',
  },
  {
    text:
      'Hur mycket kan en smart elbilsladdare integrerad i Telinet-appen minska dina elkostnader med?',
    answers: ['Upp till 10%', 'Upp till 25%', 'Upp till 50%', 'Upp till 75%'],
    correctAnswer: 'Upp till 50%',
  },
  {
    text:
      'Hur mycket lägre pris får en genomsnittlig kund som använder smart elanvändning med timpris?',
    answers: ['Upp till 5%', 'Upp till 10%', 'Upp till 15%', 'Upp till 20%'],
    correctAnswer: 'Upp till 15%',
  },
];

export default questions;
