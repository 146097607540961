import React from 'react';
import { Timpris } from './Timpris';
import { Rorligt } from './Rorligt';
import { Fastpris } from './Fastpris';

export const ElavtalMain = ({
  selectedBox,
  handleSelect,
  selectedMonth,
  handleMonthSelect,
  areaCode,
}) => {
  //return only a timpris, set isSelected to true
  return (
    <div className="elavtal-main">
      <Timpris
        key={0}
        isSelected={true}
        toggleSelect={null}
        areaCode={areaCode}
      />
      {
        //[Timpris, Rorligt, Fastpris].map((Component, index) => {
        //  if (Component === Fastpris) {
        //    return (
        //      <Fastpris
        //        isSelected={selectedBox === 2}
        //        toggleSelect={() => handleSelect(2)}
        //        selectedMonth={selectedMonth}
        //        handleMonthSelect={handleMonthSelect}
        //        areaCode={areaCode}
        //      />
        //    );
        //  } else
        //    return (
        //      <Component
        //        key={index}
        //        isSelected={selectedBox === index}
        //        toggleSelect={() => handleSelect(index)}
        //        areaCode={areaCode}
        //      />
        //    );
        //})
      }
    </div>
  );
};

export default ElavtalMain;
