import { format } from 'date-fns';
import { dateFormats } from './constants';
import styles from '../components/DatePicker/styles.module.scss';

export const dayStyles = (date, initialDate, selectedDate) => {
  if (
    format(new Date(date), dateFormats.yearMonthDay) ===
    format(initialDate, dateFormats.yearMonthDay)
  ) {
    return styles.datePickerDayToday;
  }
  if (
    format(new Date(date), dateFormats.yearMonthDay) ===
    format(selectedDate, dateFormats.yearMonthDay)
  ) {
    return styles.datePickerDaySelected;
  }
  if (
    format(new Date(date), dateFormats.yearMonthDay) <
    format(initialDate, dateFormats.yearMonthDay)
  ) {
    return styles.datePickerDayDisabled;
  } else return styles.datePickerDay;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};
