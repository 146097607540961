import React, { useState } from 'react';
import { format } from 'date-fns';
import { dateFormats, fourDaysFromNow } from 'utils/constants';

export const GlobalContextContainer = React.createContext({
  areaCode: '',
  loadingData: true,
  endpoint: '',
  facilityStartDate: '',
  startDateProxyOne: '',
  agentsList: [],
  localStorageIsFull: false,
  validation: 1,
  storeOptions: [],
  firstStepValidation: false,
  firstStepCompleted: false,
  chartData: [],
  setAreaCode: () => {},
  setLoadingData: () => {},
  setEndpoint: () => {},
  setFacilityStartDate: () => {},
  setStartDateProxyOne: () => {},
  setAgentsList: () => {},
  setLocalStorageIsFull: () => {},
  setValidation: () => {},
  setStoreOptions: () => {},
  setFirstStepValidation: () => {},
  setCompletedFirstStep: () => {},
  setChartData: () => {},
});

export const GlobalContext = (children) => {
  const [areaCode, setAreaCode] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const [endpoint, setEndpoint] = useState('');
  const [facilityStartDate, setFacilityStartDate] = useState(new Date());
  const [startDateProxyOne, setStartDateProxyOne] = useState(
    format(fourDaysFromNow, dateFormats.yearMonthDay)
  );
  const [startDate, setStartDate] = useState(new Date());
  const [storeOptions, setStoreOptions] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [localStorageIsFull, setLocalStorageIsFull] = useState(false);
  const [validation, setValidation] = useState(1);
  const [firstStepValidation, setFirstStepValidation] = useState(false);
  const [firstStepCompleted, setCompletedFirstStep] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  return (
    <GlobalContextContainer.Provider
      value={{
        areaCode,
        loadingData,
        endpoint,
        facilityStartDate,
        startDateProxyOne,
        startDate,
        storeOptions,
        agentsList,
        localStorageIsFull,
        validation,
        firstStepValidation,
        firstStepCompleted,
        chartData,
        loading,
        setAreaCode,
        setLoadingData,
        setEndpoint,
        setFacilityStartDate,
        setStartDateProxyOne,
        setStartDate,
        setStoreOptions,
        setAgentsList,
        setLocalStorageIsFull,
        setValidation,
        setFirstStepValidation,
        setCompletedFirstStep,
        setChartData,
        setLoading,
      }}
      {...children}
    />
  );
};
