export const setAssignmentDataHelper = (name) => {
  switch (name) {
    case 'building_type':
      return 'UPDATE_ACCOMODATION';
    case 'creator_confirmation':
      return 'UPDATE_CONTRACT_CHECK';
    case 'cell_phone':
      return 'PHONE_NUMBER';
    case 'agent_id':
      return 'AGENT_ID';
    default:
      return 'UPDATE_USER_INFORMATION';
  }
};
