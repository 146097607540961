import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { endpoints } from 'global/endpoints';
import { LoaderCopy } from '../LoaderCopy';

export const CalculatePriceBox = ({ areaCode, type, monthly }) => {
  //fetch price info
  //mount with price info

  return (
    <>
      <PriceBox areaCode={areaCode} type={type} monthly={monthly} />
    </>
  );
};

export const PriceBox = ({ areaCode, type, monthly }) => {
  const [elprice, setElPrice] = useState('');
  const [moms, setMoms] = useState('');
  const [rabatt, setRabatt] = useState('');
  const [totalprice, setTotalprice] = useState('');
  const [monthlyCost, setMonthlyCost] = useState('');
  const [kwh1, setKwh1] = useState('');
  const [kwh2, setKwh2] = useState('');
  const [kwh3, setKwh3] = useState('');

  const [loading, setLoading] = useState(false);

  const updatePriceData = (areaCode, type, monthly) => {
    let endpoint = `${endpoints.calculatPriceBoxData}`;
    setLoading(true);
    axios
      .get(endpoint, {
        params: {
          area_code: areaCode,
          type: type,
          monthly: monthly,
        },
      })
      .then((res) => {
        if (res) {
          if (res.data.totalPrice2000) {
            setKwh1(res.data.totalPrice2000);
          }
          if (res.data.totalPrice5000) {
            setKwh2(res.data.totalPrice5000);
          }
          if (res.data.totalPrice20000) {
            setKwh3(res.data.totalPrice20000);
          }
          if (res.data.prisspan) {
            setElPrice(res.data.prisspan);
          }
          if (res.data.moms) {
            setMoms(res.data.moms);
          }
          if (res.data.rabatt) {
            setRabatt(res.data.rabatt);
          }
          if (res.data.totalpris) {
            setTotalprice(res.data.totalpris);
          }
          if (res.data.manadsavgift) {
            setMonthlyCost(res.data.manadsavgift);
          }
          setLoading(false);
        } else {
          console.error('something went wrong in fetching data for ' + type);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    updatePriceData(areaCode, type, monthly);
  }, [areaCode, type, monthly]);

  return (
    <div className="calculate-price-wrapper">
      <div className="calculate-price-info">
        <div className="calculate-price-label-wrapper">
          <p className="calculate-price-label">Elpris:</p>

          <p className="calculate-price-label">
            {loading ? <LoaderCopy /> : `${elprice} öre`}
          </p>
        </div>
        <div className="calculate-price-label-wrapper">
          <p className="calculate-price-label">Moms:</p>
          <p className="calculate-price-label">
            {' '}
            {loading ? <LoaderCopy /> : `${moms} öre`}
          </p>
        </div>
        {/*<div className="calculate-price-label-wrapper">
          <p className="calculate-price-label redlabel">Rabatt:</p>
          <p className="calculate-price-label redlabel">
            {' '}
            {loading ? <LoaderCopy /> : `${rabatt} öre`}
          </p>
        </div>*/}
        <div className="calculate-price-label-wrapper">
          <p className="calculate-price-label boldlabel">Totalt elpris:</p>
          <p className="calculate-price-label boldlabel">
            {' '}
            {loading ? <LoaderCopy /> : `${totalprice} öre`}
          </p>
        </div>
        <div className="calculate-price-label-wrapper">
          <p className="calculate-price-label">Månadsavgift:</p>
          <p className="calculate-price-label">
            {loading ? (
              <LoaderCopy />
            ) : (
              <span>
                <span className="price-original">{monthlyCost} kr/mån</span>{' '}
                <span className="price-new">0 kr/mån</span>
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="smallLabels-wrapper">
        <div className="calculate-price-label-wrapper-small">
          <p className="calculate-price-label smalllabel2">
            Jämförspris 2 000 kWh:
          </p>
          <p className="calculate-price-label smalllabel">
            {' '}
            {loading ? <LoaderCopy /> : `${kwh1}  öre/kWh`}
          </p>
        </div>
        <div className="calculate-price-label-wrapper-small">
          <p className="calculate-price-label smalllabel2">
            Jämförspris 5 000 kWh:
          </p>
          <p className="calculate-price-label smalllabel">
            {' '}
            {loading ? <LoaderCopy /> : `${kwh2}  öre/kWh`}
          </p>
        </div>
        <div className="calculate-price-label-wrapper-small">
          <p className="calculate-price-label smalllabel2">
            Jämförspris 20 000 kWh:
          </p>
          <p className="calculate-price-label smalllabel">
            {' '}
            {loading ? <LoaderCopy /> : `${kwh3}  öre/kWh`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CalculatePriceBox;
