import React from 'react';
import { useLocation } from 'react-router';

import { Routes } from 'global/Routes';

import ConditionalRender from 'components/ConditionalRender';
import { Video } from 'components/Video';
import { Title } from 'components/Title';
import { Text } from 'components/Text';

import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import styles from './styles.module.scss';

export const AgreementsVideo = () => {
  const location = useLocation();

  const leadsVideoURL = 'https://player.vimeo.com/video/639076525';
  const otherVideoURL =
    'https://player.vimeo.com/video/204202627?dnt=1&app_id=122963';
  const timprisVideoURL = 'https://player.vimeo.com/video/680012317';

  const videoValue = () => {
    if (pathLocationCheck(location.pathname) === Routes.LEADS) {
      return leadsVideoURL;
    } else if (
      pathLocationCheck(location.pathname) === Routes.TIMPRIS ||
      pathLocationCheck(location.pathname) === Routes.EVENT
    ) {
      return timprisVideoURL;
    } else return otherVideoURL;
  };

  return (
    <div className={styles.videoWrap}>
      <div className={styles.videoDesctiption}>
        <Title title="Så funkar det" small />
        <ConditionalRender
          shouldRender={
            pathLocationCheck(location.pathname) === Routes.TIMPRIS ||
            pathLocationCheck(location.pathname) === Routes.EVENT ||
            pathLocationCheck(location.pathname) === Routes.PCE
          }
        >
          <Text large>
            <p>
              Nordpools timmätta elpris varierar över dygnet, som du ser här i
              tabellen nedan. Du följer marknadens rörliga priser timme per
              timme utifrån Nordpools timmätta spotpris. Med denna lösning kan
              du anpassa och optimera din elförbrukning över dygnet till
              skillnad från ett vanligt rörligt elavtal.
            </p>
          </Text>
        </ConditionalRender>
        <ConditionalRender
          shouldRender={
            pathLocationCheck(location.pathname) === Routes.TIMPRIS ||
            pathLocationCheck(location.pathname) === Routes.EVENT ||
            pathLocationCheck(location.pathname) === Routes.PCE
          }
        >
          <Text large>
            <p>
              Detta avtal passa således dig som kan påverka din förbrukning över
              dygnet och kan bestämma när du förbrukar som mest energi.
              Tillsammans minskar vi även energitopparna och på sikt får vi på
              så sätt ett jämnare och lägre prisläge på marknaden.
            </p>
          </Text>
        </ConditionalRender>
      </div>
      <Video videoUrl={videoValue()} />
    </div>
  );
};
