import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { endpoints } from 'global/endpoints';
import styles from './styles.module.scss';
import { areaCodeValidations, areaCodeWithLetter } from 'utils/areaCode';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';
import { Input } from 'components/Input';
import { Text } from 'components/Text';

export const AreaChange = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [areaValue, setAreaValue] = useState('');
  const [areaData, setAreaData] = useState({ id: 3 });
  const [placeholder, setPlaceholder] = useState('Ditt postnummer');
  const { setAreaCode, setLoadingData } = useContext(GlobalContextContainer);

  const [helperText, setHelperText] = useState('Priset gäller för elområde ');

  const getAreaData = useCallback(() => {
    if (areaCodeValidations(areaValue) && navigator.onLine) {
      setLoadingData(true); // Assuming you want to show loading before the call
      setHelperText('Validerar postnummer..');
      axios
        .get(
          `${endpoints.changeRegionData}?zip_code=${
            areaCodeWithLetter(areaValue) || areaValue
          }`
        )
        .then((res) => {
          if (res.data && Number(res.data.id) !== 100) {
            setAreaCode(res.data.id);
            setAreaData(res.data);
            setErrorMessage('');
          } else {
            setPlaceholder('Elområde ej definierat');
            setAreaValue('');
          }
        })
        .catch((err) => {
          console.error(err);
          setErrorMessage('Network error, please try again');
        })
        .finally(() => {
          setLoadingData(false); // Stop loading regardless of result
          setHelperText('Priset gäller för elområde ');
        });
    } else {
      setErrorMessage('Invalid area code');
      setHelperText('Priset gäller för elområde ');
    }
  }, [areaValue, setAreaCode, setLoadingData]);

  useEffect(() => {
    // Check if the input length is exactly 5 digits and valid
    if (areaValue.length === 5 && areaCodeValidations(areaValue)) {
      getAreaData();
    }
  }, [areaValue, getAreaData]);

  const handleAreaChange = (e) => {
    setAreaValue(e.target.value);
    if (e.target.value.length > 0 && !areaCodeValidations(e.target.value)) {
      setErrorMessage('Invalid area code');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <div className={styles.areaChange}>
      <div className={styles.areaChangeInputWrap}>
        <Input
          type="text"
          placeholder={placeholder}
          id={areaData.id.toString()}
          onChange={handleAreaChange}
          error={errorMessage}
          value={areaValue}
        />
      </div>
      <div className={styles.areaText}>
        <Text large>{helperText}</Text>
        <span className={styles.areaCode}> {areaData && areaData.id} </span>
        <Text bold small>
          Föregående månads snittpris.
        </Text>
      </div>
    </div>
  );
};
