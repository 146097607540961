import React, { useState } from 'react';
import { LoaderCopy } from '../utils/LoaderCopy';
import './styles.css';

export const ChooseAvtal = ({
  onSubmit,
  handleOpenModal,
  index,
  selectedMonth,
  month,
  loading,
  isModalOpen,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckedState = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="choose-avtal-wrapper">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          className="choose-avtal-checkbox"
          onChange={handleCheckedState}
        />
        <span>Jag har läst och godkänner</span>
        <a
          href="https://www.telinet.se/avtalsvillkor/privat/"
          target="_blank"
          rel="noopener noreferrer"
        >
          villkoren
        </a>
      </label>

      <CustomButton
        state={isChecked}
        onSubmit={onSubmit}
        handleOpenModal={handleOpenModal}
        index={index}
        selectedMonth={selectedMonth}
        month={month}
        loading={loading}
        isModalOpen={isModalOpen}
      />
    </div>
  );
};

export const CustomButton = ({
  state,
  onSubmit,
  handleOpenModal,
  index,
  month,
  loading,
  isModalOpen,
}) => {
  // Ensure button is enabled only if terms are agreed to and
  const isDisabled = !state || isModalOpen;

  const handleClick = () => {
    if (!isDisabled) {
      //onSubmit();
      handleOpenModal(true);
    } else {
      console.error('Button is disabled, cannot proceed.');
    }
  };

  return (
    <button
      disabled={isDisabled}
      onClick={handleClick}
      className="sign-elavtal"
    >
      {!loading ? (
        'TECKNA ELAVTAL'
      ) : (
        <>
          <LoaderCopy />
        </>
      )}
    </button>
  );
};
