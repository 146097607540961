import React from 'react';
import { faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Title } from 'components/Title';

import styles from './styles.module.scss';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';
import { useLocation } from 'react-router';

export const ModalHeader = ({
  title,
  handleMinimizedModal,
  setIsMinimizes,
  handleOpenModal,
  modalTextInfo,
}) => {
  const location = useLocation();

  const handleCloseModal = () => {
    handleOpenModal(false);
    setIsMinimizes(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <div
      className={styles.headerWrapper}
      style={{
        padding: `${
          pathLocationCheck(location.pathname) === Routes.LEADS && '13px'
        }`,
      }}
    >
      <Title title={title} className={styles.leftTitle} />
      {modalTextInfo && (
        <div className={styles.centerContainer}>
          <Title title={modalTextInfo} className={styles.modalTextInfo} />
        </div>
      )}
      <div className={styles.buttonsWrap}>
        <FontAwesomeIcon
          icon={faMinus}
          className={styles.minimizeButton}
          onClick={() => {
            setIsMinimizes(true);
            handleMinimizedModal('hidden');
            //handleOpenModal(false);
            document.body.style.overflow = 'auto';
          }}
        />
        <FontAwesomeIcon
          icon={faTimes}
          className={styles.closeButton}
          onClick={handleCloseModal}
        />
      </div>
    </div>
  );
};
