import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'components/Spinner';
import styles from './styles.module.scss';
import ConditionalRender from 'components/ConditionalRender';

export const Perk = ({ circle, value, text, light, loading, error }) => {
  return (
    <div className={styles.perkWrap}>
      <div
        className={classnames(
          circle ? styles.perkCircle : styles.perk,
          circle && light && styles.perkCircleLight,
          !value && styles.alignToTop
        )}
      >
        {error && error.err && error.res !== 503 ? (
          <p className={styles.errorMessage}>saknas</p>
        ) : (
          <div className={styles.perkContentWrap}>
            <ConditionalRender shouldRender={loading}>
              <Spinner />
            </ConditionalRender>
            <ConditionalRender shouldRender={!loading}>
              <p className={circle ? styles.perkTextLg : styles.perkText}>
                {value}
              </p>
            </ConditionalRender>

            <p className={styles.perkText}>{text}</p>
          </div>
        )}
      </div>
    </div>
  );
};
