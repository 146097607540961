import React from 'react';
import cls from 'classnames';
import styles from './styles.module.scss';

export const Row = ({ className, children, verticalAlign }) => {
  return (
    <div
      className={cls(styles.row, verticalAlign && styles.vertical, className)}
    >
      {children}
    </div>
  );
};

export const Column = ({ className, children, number }) => {
  return (
    <div
      className={cls(
        styles.column,
        className,
        number && styles['column' + number]
      )}
    >
      {children}
    </div>
  );
};
