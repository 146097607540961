import React from 'react';
import { Navigation, ContentWrap } from './components';

export const WizardStepper = ({ stepsList, children }) => {
  return (
    <div>
      <Navigation steps={stepsList} />
      <ContentWrap>{children}</ContentWrap>
    </div>
  );
};
