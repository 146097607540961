import React, { useCallback, useContext, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';

import { Title } from 'components/Title';
import { Text } from 'components/Text';
import { Input } from 'components/Input';
import { ToggleSwitch, Checkbox, Button } from 'components/Buttons';
import BottomButtons from '../../components/BottomButtons';
import { Loader } from 'components/Loader';
import ConditionalRender from 'components/ConditionalRender';

import { dateFormats } from 'utils/constants';
import {
  organizationCode,
  shortCodeOrganisation,
} from 'utils/shortCodeOrganisation';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';

import { Routes } from 'global/Routes';
import { GlobalContextContainer } from '../../../MediaMarkt/globalContext';

import styles from './styles.module.scss';
import { useState } from 'react';
import { endpoints } from 'global/endpoints';
import {
  AGENT_ID,
  AGREEMENT_OPTIONS,
  EMAIL,
  PHONE_NUMBER,
  TOGGLE_BUTTON,
  UPLOAD_PHOTO,
  UPDATE_AGENT_ID,
  UPDATE_BANK_ID,
} from 'utils/reducerConstants';
import { validateEmail } from 'utils/userInformationValidations';
import { validatePhone } from 'utils/userInformationValidations';
import { areaCodeWithoutLetters } from 'utils/areaCode';
import { BankId } from '../BankId';
import { Column } from 'components/Grid';
import { Row } from 'components/Grid';

const Contract = ({
  stepsList,
  setCurrentStep,
  setStepsList,
  currentStep,
  assignment,
  setAssignmentData,
  priceDataToSend,
  scriveData,
}) => {
  const [stepValidation, setStepValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPhone, setInvalidPhone] = useState(false);

  const {
    agentsList,
    localStorageIsFull,
    setLocalStorageIsFull,
    validation,
  } = useContext(GlobalContextContainer);
  const {
    address,
    zip_code,
    city,
    cell_phone,
    email,
    soc_id,
    bank_id_sign,
    bank_id_signature,
    zip_code2,
  } = assignment.data;

  const { fileName } = assignment;

  const location = useLocation();
  const organisation = shortCodeOrganisation(location.pathname);
  const agentsListFromStorage = localStorage.getItem(
    `agentsList${organisation}`
  );

  let agentsListItems = [];
  if (pathLocationCheck === Routes.PCE) {
    agentsListItems = {
      agent_2310: '2310',
      agent_2311: '2311',
      agent_2312: '2312',
      agent_2313: '2313',
      agent_2314: '2314',
      agent_2315: '2315',
      agent_2316: '2316',
      agent_2317: '2317',
      agent_2318: '2318',
      agent_2319: '2319',
      agent_2398: '2398',
      agent_2399: '2399',
      agent_2400: '2400',
      agent_2401: '2401',
      agent_2402: '2402',
      agent_2403: '2403',
      agent_2404: '2404',
      agent_2405: '2405',
      agent_2406: '2406',
      agent_2407: '2407',
    };
  } else {
    agentsListItems = navigator.onLine
      ? agentsList
      : JSON.parse(agentsListFromStorage);
  }

  const agentIdError =
    assignment.agentId > Object.keys(agentsListItems).length ||
    (Number(assignment.agentId) === 0 && assignment.agentId !== '');

  const cellPhoneError = cell_phone !== '' && cell_phone.length < 9;

  const handleAgentId = (value) => {
    setAssignmentData({
      type: UPDATE_AGENT_ID,
      payload: value,
    });
    const agentKey = Object.keys(agentsListItems)[value - 1];
    const agentIdValue = agentsListItems[agentKey];
    setAssignmentData({
      type: AGENT_ID,
      payload: agentIdValue,
    });
  };

  const handleEmailAddress = useCallback(
    (value) => {
      const email = value.trim();
      const validEmail = validateEmail(email);
      if (!validEmail && email.length > 0) {
        setInvalidEmail(true);
      } else {
        setInvalidEmail(false);
      }
      setAssignmentData({
        type: EMAIL,
        payload: email,
      });
    },
    [setAssignmentData]
  );

  useEffect(() => {
    handleEmailAddress(email);
  }, [handleEmailAddress, email]);

  const handlePhoneNumber = useCallback((value) => {
    const isPhoneValid = validatePhone(value);
    const result = value.replace(/\D/g, '');

    if (!isPhoneValid && isPhoneValid.length > 9) {
      setInvalidPhone(true);
    } else {
      setInvalidPhone(false);
    }

    setAssignmentData(
      {
        type: PHONE_NUMBER,
        payload: result,
      },
      [setAssignmentData]
    );
  });

  const handleUploadedPhoto = (payload) => {
    setCansign(true);
    setAssignmentData({
      type: UPLOAD_PHOTO,
      payload,
    });
  };

  const handleBankId = (payload) => {
    setAssignmentData({
      type: UPDATE_BANK_ID,
      payload,
    });
  };

  const [cansign, setCansign] = useState(false);
  const handleOrderRef = (req) => {
    _setOrderRef(req);
  };
  const handleSignStatus = (req) => {
    setSignStatus(req);
  };
  const [_orderRef, _setOrderRef] = useState('');
  const [_signStatus, setSignStatus] = useState(null);

  const handleSignButton = (cansign) => {
    setCansign(!cansign);
  };
  const { address: address1, ...otherProps } = assignment.data;
  const newData = { address1, ...otherProps };

  const dataToSend = {
    ...newData,
    ...(assignment.userExisting.customerId && {
      customer_id: parseInt(assignment.userExisting.customerId),
    }),
    validated: validation,
    timestamp: format(new Date(), dateFormats.yearMonthDayTime),
    organization: organizationCode(location.pathname),
    zip_code: areaCodeWithoutLetters(zip_code),
    zip_code2: zip_code2 ? areaCodeWithoutLetters(zip_code2) : '',
    ...(_signStatus === 'complete' && {
      bank_id_sign: true,
    }),
    ...(_orderRef !== '' && {
      orderRef: _orderRef,
    }),
    ...(priceDataToSend !== null && {
      price_group_id: priceDataToSend,
    }),
    ...(scriveData !== null && {
      scriveData: scriveData,
    }),
  };

  const goToNextStep = () => {
    let newList = stepsList;

    newList[currentStep + 1] = {
      ...newList[currentStep + 1],
      selected: true,
      completed: false,
    };
    newList[currentStep] = {
      ...newList[currentStep],
      selected: false,
      completed: true,
    };
    setStepsList(newList);
    setCurrentStep(currentStep + 1);
    setLoading(false);
  };

  const handleCreateContract = () => {
    setLoading(true);

    //let endpoint = '';

    //if (fileName !== '' && _orderRef === '')
    //  endpoint = endpoints.signPOAWithFile;
    //else if (fileName === '' && _orderRef !== '')
    //  endpoint = endpoints.contractSignForBankId;
    let endpoint = endpoints.contractSignForBankId;

    axios
      .post(endpoint, dataToSend)
      .then((res) => {
        if (res.status === 200) {
          goToNextStep();
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const handleOptionsChange = (index) => {
    var newAggrementsOptions = JSON.parse(
      JSON.stringify(assignment.agreementOptions)
    );

    newAggrementsOptions[index] = {
      ...newAggrementsOptions[index],
      value: !newAggrementsOptions[index].value,
    };
    setAssignmentData({
      type: AGREEMENT_OPTIONS,
      payload: newAggrementsOptions,
    });
  };

  const checkAvailable = () => {
    const test = 'test';
    try {
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };

  const addContractToLocalStorage = () => {
    const allContracts =
      JSON.parse(
        localStorage.getItem(
          `contracts${shortCodeOrganisation(location.pathname)}`
        )
      ) || [];
    allContracts.push(dataToSend);
    if (checkAvailable() === true) {
      // available
      localStorage.setItem(
        `contracts${shortCodeOrganisation(location.pathname)}`,
        JSON.stringify(allContracts)
      );
    } else {
      // unavailable
      setLocalStorageIsFull(true);
    }
  };

  const validateFields = useCallback(() => {
    if (
      !agentIdError &&
      !invalidEmail &&
      email.length > 0 &&
      !invalidPhone &&
      cell_phone &&
      cell_phone.length >= 9 &&
      (assignment.fileName ||
        cansign ||
        bank_id_signature ||
        (scriveData && bank_id_signature))
    ) {
      return true;
    }
    return false;
  }, [
    agentIdError,
    assignment.fileName,
    bank_id_signature,
    cell_phone,
    email,
    invalidEmail,
    invalidPhone,
    cansign,
  ]);

  const areChecked = () => {
    return assignment.agreementOptions.some((item) => item.value === false);
  };
  const handleStepValidations = () => {
    if (validateFields() && !areChecked()) {
      setStepValidation(true);
      if (navigator.onLine) {
        handleCreateContract();
      } else {
        addContractToLocalStorage();
        goToNextStep();
      }
    } else {
      setStepValidation(false);
    }
  };

  const handleToggleButton = () => {
    setAssignmentData({
      type: TOGGLE_BUTTON,
      payload: !assignment.toggleButton,
    });
  };
  const disabled = () => {
    return validateFields() && !areChecked();
  };

  return (
    <div>
      <Title title="Sista steget!" />
      <Text medium className={styles.agentId}>
        Agent-ID: {assignment.agentId}
      </Text>
      <div className={styles.twoColumns}>
        <div className={styles.dropDownWrap}>
          <Text medium className={styles.agentIdSelect}>
            Ändra Agent-ID?
          </Text>
          <Input
            large
            onChange={(e) => handleAgentId(e.target.value)}
            outline
            value={assignment.agentId || ''}
            type="number"
          />
        </div>
        <div />
      </div>
      <div className={styles.twoColumns}>
        <Input
          label="Epost"
          required
          placeholder="namn@maildomain.com..."
          large
          onChange={(e) => handleEmailAddress(e.target.value)}
          outline
          value={email || ''}
          error={invalidEmail}
        />
        <Input
          label="Mobiltelefon"
          required
          placeholder="Endast nummer, utan mellanslag och bindestreck..."
          large
          type="tel"
          pattern="^-?[0-9]\d*\.?\d*$"
          onChange={(e) => handlePhoneNumber(e.target.value)}
          outline
          value={cell_phone || ''}
          error={cellPhoneError}
        />
      </div>
      <Text medium className={styles.agentId}>
        Beställningen avser adressen: {address}, {zip_code} {city}
      </Text>

      <Row>
        <Column number="1">
          <ToggleSwitch
            isChecked={assignment.toggleButton}
            handleToggle={handleToggleButton}
          />
        </Column>

        <Column number="2">
          <div className={styles.toggleLabel}>
            <Text medium>
              Jag har läst och godkänner
              <a
                className={styles.terms}
                href="https://www.telinet.se/avtalsvillkor/"
              >
                villkoren.
              </a>
            </Text>
          </div>
        </Column>
      </Row>
      {assignment.toggleButton && (
        <div className={styles.contractAgreementsWrap}>
          <div className={styles.separateLine} />
          <div className={styles.contractAgreements}>
            <Title
              smallStatic
              title="Håller du med?"
              className={styles.agreementsTitle}
            />
            {assignment.agreementOptions.map((option, index) => (
              <div className={styles.agreementsOption} key={option.id}>
                <Checkbox
                  name={option.name}
                  label={
                    pathLocationCheck(location.pathname) === Routes.EVENT &&
                    index === 1
                      ? option.label_2
                      : option.label
                  }
                  value={option.value}
                  onChange={() => handleOptionsChange(index)}
                  id={option.name + option.id}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <BankId
        value={bank_id_sign}
        handleBankId={handleBankId}
        signature={bank_id_signature}
        signData={{
          address1: address,
          city,
          personal_number: soc_id,
          organization_id: organizationCode(location.pathname),
        }}
        handleUploadedPhoto={handleUploadedPhoto}
        fileName={fileName}
        handleSignButton={handleSignButton}
        handleSignStatus={handleSignStatus}
        handleOrderRef={handleOrderRef}
      />
      <ConditionalRender shouldRender={loading}>
        <Loader />
      </ConditionalRender>
      <ConditionalRender shouldRender={localStorageIsFull}>
        <Text medium error className={styles.localStorageError}>
          Local storage is full.
        </Text>
      </ConditionalRender>
      {/* <ConditionalRender shouldRender={errorMessage.length > 0}>
        <div className={styles.contractErrorMessage}>
          <Text medium error>
            {errorMessage}
          </Text>
        </div>
      </ConditionalRender> */}
      <BottomButtons
        stepsList={stepsList}
        setCurrentStep={setCurrentStep}
        setStepsList={setStepsList}
        currentStep={currentStep}
        validStep={stepValidation}
        handleSubmitButton={handleStepValidations}
        setAssignmentData={setAssignmentData}
        disabled={!disabled() || loading}
        assignment={assignment}
        canSign={cansign}
      />
    </div>
  );
};

export default Contract;
