import React from 'react';
import './styles.css';

export const LoaderCopy = ({ noMargin = false }) => (
  <div className={noMargin ? 'spinnercopy noMargin' : 'spinnercopy'}>
    <div className={'bounce1'}></div>
    <div className={'bounce2'}></div>
    <div className={'bounce3'}></div>
  </div>
);
