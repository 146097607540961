import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/Text';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  pathLocationCheck,
  shortCodeOrganisation,
} from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';

export const Widget = ({ date, time, isOnline, timeUpdated }) => {
  const location = useLocation();
  const allItemsInLocalStorage = JSON.parse(
    localStorage.getItem(
      `${
        pathLocationCheck(location.pathname) === Routes.LEADS
          ? 'leads'
          : 'contracts'
      }${shortCodeOrganisation(location.pathname)}`
    )
  );
  const [contractsCount, setContractsCount] = useState();

  useEffect(() => {
    //console.log('Widget component, in useEffect 1');
    if (allItemsInLocalStorage && !isOnline) {
      setContractsCount(allItemsInLocalStorage.length);
    } else {
      if (allItemsInLocalStorage && allItemsInLocalStorage.length !== 0) {
        setContractsCount(allItemsInLocalStorage.length);
      } else {
        setContractsCount(0);
      }
    }
  }, [allItemsInLocalStorage, isOnline, timeUpdated]);

  return (
    <div className={styles.widgetContainer}>
      <div className={styles.status}>
        You are
        <Text primary bold medium>
          {isOnline ? 'online' : 'offline'}
        </Text>
      </div>
      <div className={styles.details}>
        <div>
          Page last updated on
          <Text bold medium>
            {date}
          </Text>
          at
          <Text bold medium>
            {time}
          </Text>
        </div>
        <div>
          Pending requests:
          <Text bold medium>
            {contractsCount || 0}
          </Text>
        </div>
      </div>
    </div>
  );
};
