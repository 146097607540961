import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { Text } from 'components/Text';
import ConditionalRender from 'components/ConditionalRender';
import { shortCodeOrganisation } from 'utils/shortCodeOrganisation';
import { useLocation } from 'react-router';

export const NoStepperDropdown = ({
  storeOptions,
  center,
  showErrorMessage,
  onSelect,
  value,
  onFocus,
  label,
  required,
  selected,
}) => {
  const location = useLocation();

  const options =
    storeOptions ||
    JSON.parse(
      localStorage.getItem(
        `storeOptions${shortCodeOrganisation(location.pathname)}`
      )
    );

  return (
    <div
      className={classnames(
        center && styles.NoStepperDropdownWrapCenter,
        styles.dropDownWrap
      )}
    >
      <div className={styles.text}>
        <Text medium>
          {label}
          {required && <span className={styles.required}>*</span>}
        </Text>
      </div>
      <select
        onFocus={onFocus}
        onChange={onSelect}
        className={classnames(showErrorMessage && styles.error)}
        value={value}
      >
        <option className={styles.emptyOption} value="">
          {''}
        </option>
        {options &&
          Object.entries(options)
            .sort((a, b) => (a[1] > b[1] ? 1 : -1))
            .map(([k, v]) => (
              <option
                className={styles.dropDownOption}
                key={v}
                value={selected === 'key' ? k : v}
              >
                {v}
              </option>
            ))}
      </select>
      <ConditionalRender shouldRender={showErrorMessage}>
        <Text bold error>
          Välj ett giltigt butik-id
        </Text>
      </ConditionalRender>
    </div>
  );
};
