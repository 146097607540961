import React from 'react';

export const DottedArrowUp = ({ color = 'lightblue' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dotted-arrow"
  >
    <circle cx="5" cy="20" r="1.5" />
    <circle cx="25" cy="20" r="1.5" />
    <circle cx="10" cy="12" r="1.5" />
    <circle cx="20" cy="12" r="1.5" />
    <circle cx="15" cy="5" r="1.5" />
  </svg>
);

export const DottedArrowDown = ({ color = 'lightblue' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="dotted-arrow"
  >
    <circle cx="5" cy="10" r="1.5" />
    <circle cx="25" cy="10" r="1.5" />
    <circle cx="10" cy="18" r="1.5" />
    <circle cx="20" cy="18" r="1.5" />
    <circle cx="15" cy="25" r="1.5" />
  </svg>
);
