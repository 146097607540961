import React from 'react';
import { Button } from 'components/Buttons';
import styles from './styles.module.scss';

const BottomButtons = ({
  stepsList,
  handleClick,
  currentStep,
  setCurrentStep,
  setStepsList,
  validStep,
  handleSubmitButton,
  disabled,
  canSign,
}) => {
  const lastStepSelected =
    stepsList &&
    stepsList[stepsList.length - 2] &&
    stepsList[stepsList.length - 2].selected;
  const firstStepSelected = stepsList && stepsList[0] && stepsList[0].completed;

  const goToStep = (type) => {
    const stepNumber = type === 'forward' ? currentStep + 1 : currentStep - 1;
    let newList = stepsList;
    newList[stepNumber] = {
      ...newList[stepNumber],
      selected: true,
      completed: false,
    };
    newList[currentStep] = {
      ...newList[currentStep],
      selected: false,
      completed: true,
    };
    setStepsList(newList);
    setCurrentStep(stepNumber);
  };

  const handleNextClick = () => {
    if (handleClick) {
      handleClick();
    }
    if (validStep) {
      goToStep('forward');
    }
  };

  const handleBackClick = () => {
    goToStep('back');
  };

  return (
    <div className={styles.bottomButtonsWrap}>
      {firstStepSelected && (
        <Button backButton text="Tillbaka" onClick={handleBackClick} />
      )}
      {lastStepSelected ? (
        <Button
          submitButton
          text="Teckna"
          onClick={() => handleSubmitButton()}
          disabled={disabled}
          //{typeof canSign !== 'undefined' ? !canSign : disabled}
        />
      ) : (
        <Button
          nextButton
          text="Nästa"
          onClick={handleNextClick}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default BottomButtons;
