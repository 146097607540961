import React from 'react';
import { Image } from 'components/Image';
import loader from 'global/images/source-1.gif';
import styles from './styles.module.scss';

export const Spinner = () => (
  <div className={styles.iconWrap}>
    <Image imageUrl={loader} center />
  </div>
);
