import {
  faIdCard,
  faUser,
  faHome,
  faSignal,
  faEnvelope,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

export const steps = [
  {
    id: 1,
    name: 'securityNumber',
    icon: faIdCard,
    completed: false,
    selected: true,
  },
  {
    id: 2,
    name: 'userInformation',
    icon: faUser,
    completed: false,
    selected: false,
  },
  {
    id: 3,
    name: 'facilityInformation',
    icon: faHome,
    completed: false,
    selected: false,
  },
  {
    id: 4,
    name: 'store',
    icon: faSignal,
    completed: false,
    selected: false,
  },
  {
    id: 5,
    name: 'contract',
    icon: faEnvelope,
    completed: false,
    selected: false,
  },
  {
    id: 6,
    name: 'success',
    icon: faCheck,
    completed: false,
    selected: false,
  },
];
