import React from 'react';

import { Text } from 'components/Text';
import { Image } from 'components/Image';

import arrowDown from 'global/images/arrow-down.png';

import styles from './styles.module.scss';

const ReadMore = () => {
  return (
    <div className={styles.readMoreWrap}>
      <Text medium bold>
        <p>Läs mer</p>
      </Text>
      <div
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className={styles.downArrow}
      >
        <Image center imageUrl={arrowDown} />
      </div>
    </div>
  );
};

export default ReadMore;
