import React from 'react';
import './styles.css';

export const Badges = ({ badges }) => {
  return (
    <div className="badges-container">
      {badges &&
        badges.map((badge, index) => (
          <img
            key={index}
            src={badge}
            alt={`badge-${index}`}
            className="badge"
          />
        ))}
    </div>
  );
};

export default Badges;
