import React, { useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import chartOptions from './chartOptions';
import { options } from './chartOptions';
import { GlobalContextContainer } from 'containers/MediaMarkt/globalContext';
import { BarElement, registerables, Chart as ChartJs } from 'chart.js';

ChartJs.register(BarElement, ...registerables);

export const Chart = ({ apiConnection, isTimprisContract }) => {
  const months = [];
  const monthValues = [];
  const days = [];
  const daysValues = [];

  const { chartData } = useContext(GlobalContextContainer);
  const cachedChartData = JSON.parse(window.localStorage.getItem('chartData'));

  if (
    apiConnection === false &&
    cachedChartData &&
    Array.isArray(cachedChartData) &&
    navigator.onLine
  ) {
    cachedChartData.forEach((item) => {
      if (isTimprisContract === true) {
        days.push(item.label);
        daysValues.push(item.value.price);
      } else {
        months.push(item.month);
        monthValues.push(Number(item.data));
      }
    });
  } else if (
    chartData &&
    Array.isArray(chartData) &&
    chartData.length > 0 &&
    navigator.onLine
  ) {
    chartData.forEach((item) => {
      if (isTimprisContract === true) {
        days.push(item.label);
        if (item.value) {
          daysValues.push(item.value.price);
        } else {
          console.error('item.value is undefined for item', item);
        }
      } else {
        months.push(item.month);
        monthValues.push(Number(item.data));
      }
    });
  } else if (
    cachedChartData &&
    Array.isArray(cachedChartData) &&
    cachedChartData.length > 0 &&
    !navigator.onLine
  ) {
    cachedChartData.forEach((item) => {
      if (isTimprisContract === true) {
        days.push(item.label);
        daysValues.push(item.value.price);
      } else {
        months.push(item.month);
        monthValues.push(Number(item.data));
      }
    });
  }

  return (
    <Bar
      data={
        isTimprisContract
          ? chartOptions(days, daysValues)
          : chartOptions(months, monthValues)
      }
      options={options(isTimprisContract, cachedChartData)}
      height={369}
      width={480}
    />
  );
};
