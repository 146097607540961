import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const Title = ({
  title,
  small,
  medium,
  large,
  smallStatic,
  light,
  center,
  className,
}) => (
  <div
    className={classnames(
      styles.title,
      small && styles.titleSmall,
      medium && styles.titileMedium,
      large && styles.titleLarge,
      smallStatic && styles.titleSmallStatic,
      light && styles.titleLight,
      center && styles.titleCenter,
      className
    )}
  >
    {title}
  </div>
);
