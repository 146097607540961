import React from 'react';
import telinetLogo from 'global/images/Logo.png';
import { Image } from 'components/Image';
import { Text } from 'components/Text';
import styles from './styles.module.scss';

export const NotFound = () => (
  <div>
    <div className={styles.notFoundHeader}>
      <Image imageUrl={telinetLogo} alt="logo" />
    </div>
    <div className={styles.notFoundTextWrap}>
      <Text xl white>
        404-Ooops Strömmen gick!
      </Text>
    </div>
  </div>
);
