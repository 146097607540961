function convertLabelToHour(label) {
  // Converts a label like 'kl 12' into '12:00 - 13:00'
  if (label && label.includes('kl')) {
    const hour = parseInt(label.split('kl ')[1], 10);
    const nextHour = (hour + 1) % 24;
    return `${hour < 10 ? '0' + hour : hour}:00 - ${
      nextHour < 10 ? '0' + nextHour : nextHour
    }:00`;
  }
  return '00:00 - 01:00'; // Fallback
}

function convertLabelHoverString(firstHour, secondHour, date) {
  let multistringText = [`${firstHour} - ${secondHour}`];
  multistringText.push(`   ${date}`);
  return multistringText;
}

function chartOptions(labels, data) {
  const chartData = {
    labels: labels,
    backgroundColor: '#0a416e',
    datasets: [
      {
        label: '',
        backgroundColor: '#2dc0d0',
        borderWidth: 0,
        data: data,
        barPercentage: 1,
      },
    ],
  };
  return chartData;
}

export function options(isTimpris, dataOptions) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataIndex = tooltipItem.dataIndex;
            const price = dataOptions[dataIndex].value.price;
            const date = dataOptions[dataIndex].date;
            const label = dataOptions[dataIndex].label;
            const formattedTime = convertLabelToHour(label); // converts 'kl 12' to '12:00 - 13:00'
            return [`${price}`, `${formattedTime}`, `${date}`];
          },
          title: function (items, data) {
            if (items.length > 0 && data && data.labels && isTimpris) {
              const currentIndex = items[0].index;
              const currentHour = convertLabelToHour(data.labels[currentIndex]);
              const nextIndex =
                currentIndex + 1 < data.labels.length
                  ? currentIndex + 1
                  : currentIndex;
              const nextHour = convertLabelToHour(data.labels[nextIndex]);
              const date =
                data.datasets[0].data[currentIndex]?.date || 'No Date';
              return convertLabelHoverString(currentHour, nextHour, date);
            }
            return ''; // Fallback in case data is not properly set
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        ticks: {
          color: 'white',
        },
      },
      x: {
        ticks: {
          color: 'white',
          autoSkip: false,
        },
      },
    },
    layout: {
      padding: {
        left: 20,
        right: 30,
        top: 20,
        bottom: 20,
      },
    },
  };

  return options;
}

export default chartOptions;
