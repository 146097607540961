import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

export const Image = ({ imageUrl, imageAlt, center }) => (
  <div
    className={classnames(styles.imageWrap, center && styles.imageWrapCenter)}
  >
    <img src={imageUrl} alt={imageAlt} />
  </div>
);
