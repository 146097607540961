import React from 'react';
import classnames from 'classnames';
import { Text } from 'components/Text';
import styles from './styles.module.scss';

const RadioButton = ({
  isChecked,
  disabled,
  onChange,
  name,
  id,
  value,
  label,
}) => {
  const isIpad =
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
    navigator.platform === 'iPad';

  return (
    <div
      className={classnames(
        styles.radioButtonWrap,
        isIpad ? styles.ipadRadioButton : ''
      )}
    >
      <input
        type="radio"
        value={value}
        checked={isChecked}
        disabled={disabled}
        onChange={onChange}
        name={name}
        id={id}
      />

      <label htmlFor={id}>
        <Text medium>{label}</Text>
      </label>
    </div>
  );
};
export default RadioButton;
