import React, { useEffect } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { SELECTED_STORE } from 'utils/reducerConstants';
import { Text } from 'components/Text';
import ConditionalRender from 'components/ConditionalRender';

export const DropDown = ({
  options,
  center,
  selectedItem,
  setSelectedItem,
  setStepValidation,
  showErrorMessage,
  setAssignmentData,
}) => {
  useEffect(() => {
    if (selectedItem && Object.keys(selectedItem).length > 0) {
      const storeId = Object.values(selectedItem)[0];
      setAssignmentData({
        type: SELECTED_STORE,
        payload: storeId,
      });
      setStepValidation(true);
    } else {
      setStepValidation(false);
    }
  }, [selectedItem, setAssignmentData, setStepValidation]);

  const onSelect = (selected) => {
    setSelectedItem({ [selected]: options[selected] });
  };
  return (
    <div
      className={classnames(
        center && styles.dropDownWrapCenter,
        styles.dropDownWrap
      )}
    >
      <select onChange={(e) => onSelect(e.target.value)}>
        <option className={styles.emptyOption} value="">
          {Object.keys(selectedItem)[0] || ''}
        </option>
        {Object.keys(options).map((option) => (
          <option className={styles.dropDownOption} key={option}>
            {option}
          </option>
        ))}
      </select>
      <ConditionalRender shouldRender={showErrorMessage}>
        <Text bold error>
          Välj ett giltigt butik-id
        </Text>
      </ConditionalRender>
    </div>
  );
};
