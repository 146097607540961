import { Text } from 'components/Text';
import { Title } from 'components/Title';
import React from 'react';

import styles from './styles.module.scss';

export const ComparativePrice = () => (
  <div>
    <Title small title="Jämförpris" className={styles.title} />
    <Text large className={styles.firstParagraph}>
      Jämförpriset innehåller alla fasta och rörliga kostnader för avtalet.
      Jämförpriset uttrycks i öre per kWh.
    </Text>
    <Text className={styles.secondParagraph}>
      Syftet med jämförpriser är att du enkelt ska kunna jämföra
      elhandelsföretagens priser. Kom ihåg att nätavgifter aldrig ingår i
      jämförpriset.
    </Text>
  </div>
);
