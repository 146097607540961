import React from 'react';
import { useLocation } from 'react-router-dom';

import { Price } from 'components/Price';
import { Title } from 'components/Title';
import ConditionalRender from 'components/ConditionalRender';

import styles from './styles.module.scss';

import { pathLocationCheck } from 'utils/shortCodeOrganisation';
import { Routes } from 'global/Routes';

export const FeesAndTaxes = ({ data, loading, error }) => {
  const location = useLocation();
  return (
    <div className={styles.feesAndTaxes}>
      <Title title="Avgifter & skatter" small className={styles.title} />
      <Price
        label="Månadsavgift:"
        price={
          pathLocationCheck(location.pathname) === Routes.PCE ? (
            <>
              <span style={{ color: 'red' }}>0*</span>
              <span
                style={{
                  position: 'relative',
                  //display: 'inline-block',
                  marginLeft: '4px',
                }}
              >
                59
                <span
                  style={{
                    content: '""',
                    position: 'absolute',
                    left: '-10%',
                    top: 'calc(50% + -1px)',
                    width: '120%',
                    borderTop: '2px solid #0a416e',
                    transform: 'rotate(-45deg)',
                    height: 0,
                    zIndex: 1,
                  }}
                ></span>
              </span>
            </>
          ) : (
            data.manadsavgift
          )
        }
        currencyKr
        loading={loading}
        error={error}
      />
      <Price
        label="Elpris:"
        price={data.prisspan}
        currencyOre
        loading={loading}
        error={error}
      />
      <ConditionalRender
        shouldRender={
          pathLocationCheck(location.pathname) !== Routes.TIMPRIS &&
          pathLocationCheck(location.pathname) !== Routes.PCE &&
          pathLocationCheck(location.pathname) !== Routes.EVENT
        }
      >
        <Price
          label="Elcertifikat:"
          price={data.elcertifikat}
          currencyOre
          loading={loading}
          error={error}
        />
      </ConditionalRender>
      {/* <ConditionalRender
        shouldRender={pathLocationCheck(location.pathname) === Routes.TIMPRIS}
      >
        <Price
          label="Påslag:"
          price={data.supplement_price}
          currencyOre
          loading={loading}
          error={error}
        />
      </ConditionalRender> */}
      <Price
        label="Moms:"
        price={data.moms}
        currencyOre
        loading={loading}
        error={error}
      />
      <Price
        label="Totalpris (inkl skatter och avgifter):"
        price={data.totalpris}
        currencyOre
        bold
        loading={loading}
        error={error}
      />
    </div>
  );
};
