import React from 'react';
import styles from './styles.module.scss';

export const Video = ({ videoUrl, title }) => (
  <div className={styles.iFrameWrap}>
    <iframe
      className={styles.iFrameResponsive}
      src={videoUrl}
      frameBorder="0"
      title={title}
    ></iframe>
  </div>
);
