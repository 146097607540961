import { createContext, useRef } from 'react';

export const IntervalContext = createContext(null);

export const IntervalProvider = ({ children }) => {
  const intervalIdRef = useRef(null);
  const isCancelledRef = useRef(false);

  return (
    <IntervalContext.Provider value={{ intervalIdRef, isCancelledRef }}>
      {children}
    </IntervalContext.Provider>
  );
};
