import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Text } from 'components/Text';
import { Image } from 'components/Image';
import { Title } from 'components/Title';
import contractTypeImage from 'global/images/movable-electricity-icon.png';
import styles from './styles.module.scss';

import { RESET_MODAL } from 'utils/reducerConstants';
import { pathLocationCheck } from 'utils/shortCodeOrganisation';

import { Routes } from 'global/Routes';

const SuccessMessage = ({ setAssignmentData, defaultAssignment }) => {
  const location = useLocation();
  useEffect(() => {
    setAssignmentData({
      type: RESET_MODAL,
      payload: { defaultAssignment },
    });
  }, [defaultAssignment, setAssignmentData]);

  return (
    <div>
      <div className={styles.successWrap}>
        <Title large className={styles.success} title="Grattis!" />
        <Text large primary bold className={styles.message}>
          Till ett bra elavtal!
        </Text>
      </div>
      <div className={styles.contractNext}>
        <div className={styles.imageWrap}>
          <Image
            imageUrl={contractTypeImage}
            imageAlt="contract type image"
            center
          />
          <Text primary medium bold className={styles.imageDescription}>
            {pathLocationCheck(location.pathname) === Routes.TIMPRIS
              ? `Rörligt timpris`
              : `Rörligt elpris`}
          </Text>
        </div>
        <div className={styles.description}>
          <Title
            large
            title="Vad händer nu?"
            className={styles.descsriptionTitle}
          />
          <Text primary medium className={styles.descriptionText}>
            Nu börjar vi jobba med din ansökan. När allt är färdigt skickar vi
            dig en bekräftelse via mail. Har du några frågor är du varmt
            välkommen att kontakta oss!
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
