import React, { useState, useEffect, useCallback, useContext } from 'react';
import classnames from 'classnames';
import axios from 'axios';
import { format } from 'date-fns';
import { dateFormats } from 'utils/constants';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Image } from 'components/Image';
import { Modal } from 'components/Modal';
import { AssignmentModalContent } from 'containers/AssignmentModalContent';
import { MinimizedModal } from 'components/Modal/components/MinimizedModal';
import ConditionalRender from 'components/ConditionalRender';
import { Button } from 'components/Buttons';
import { NoStepperModalContent } from 'containers/NoStepperModalContent';
import { Widget } from 'components/Widget';

import { Agreements } from 'containers/Agreements';
import { Prices } from 'containers/Prices';
import { History } from 'containers/History';

import telinetLogo from 'global/images/Logo.png';
import separatorDark from 'global/images/separator-dark.png';
import { endpoints } from 'global/endpoints';
import { Routes } from 'global/Routes';

import { GlobalContextContainer } from './globalContext';
import {
  pathLocationCheck,
  shortCodeOrganisation,
} from 'utils/shortCodeOrganisation';
import styles from './styles.module.scss';
import 'react-toastify/dist/ReactToastify.css';
// import { Route } from 'react-router-dom/cjs/react-router-dom.min';

export const MediaMarkt = () => {
  const [pricesData, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isMinimized, setIsMinimizes] = useState(false);
  const [lastUpdated, setLastUpdated] = useState({});
  const [showModal, setShowModal] = useState('hidden');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [timeUpdated, setTimeUpdated] = useState(new Date().getTime());
  const [priceApiConnection, setPriceApiConnection] = useState();
  const [chartApiConnection, setChartApiConnection] = useState();

  const location = useLocation();

  const cachedRequestData = JSON.parse(
    window.localStorage.getItem('lastUpdate')
  );
  const cachedPricesData = JSON.parse(
    window.localStorage.getItem('pricesData')
  );

  const organisation = shortCodeOrganisation(location.pathname);

  const callsToMake = async () => {
    const allItemsInLocalStorage = JSON.parse(
      localStorage.getItem(
        `${
          pathLocationCheck(location.pathname) === Routes.LEADS
            ? 'leads'
            : 'contracts'
        }${shortCodeOrganisation(location.pathname)}`
      )
    );

    let deletedItems = [];
    for (let i = 0; i < allItemsInLocalStorage.length; i++) {
      if (navigator.onLine) {
        await axios
          .post(
            pathLocationCheck(location.pathname) === Routes.LEADS
              ? endpoints.assignLeads
              : endpoints.assignContract,
            allItemsInLocalStorage[i]
          )
          .then((res) => {
            if (res.status === 200) {
              deletedItems.push(allItemsInLocalStorage[i]);

              if (i + 1 === allItemsInLocalStorage.length) {
                const ItemsToStay = allItemsInLocalStorage.filter(
                  (el) => !deletedItems.includes(el)
                );
                localStorage.setItem(
                  `${
                    pathLocationCheck(location.pathname) === Routes.LEADS
                      ? 'leads'
                      : 'contracts'
                  }${shortCodeOrganisation(location.pathname)}`,
                  JSON.stringify(ItemsToStay)
                );
                setTimeUpdated(new Date().getTime());
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (pathLocationCheck(location.pathname) === Routes.LEADS)
      handleResetNoStepperModal();
  };
  const makeCallFromLocalStorage = useCallback(() => {
    const allItems = JSON.parse(
      localStorage.getItem(
        `${
          pathLocationCheck(location.pathname) === Routes.LEADS
            ? 'leads'
            : 'contracts'
        }${shortCodeOrganisation(location.pathname)}`
      )
    );
    if (allItems && allItems.length > 0) {
      callsToMake();
    }
  }, [location]);
  const [pricesError, setPricesError] = useState({ err: false, res: {} });
  const {
    areaCode,
    loadingData,
    setLoadingData,
    setFacilityStartDate,
    setAgentsList,
    setStoreOptions,
    setValidation,
    setStartDate,
    validation,
    setChartData,
    setLoading,
  } = useContext(GlobalContextContainer);

  window.onoffline = () => {
    setIsOnline(false);
    if (isOpen) {
      setValidation(0);
    }
  };
  window.ononline = () => {
    if (!isOpen) {
      setValidation(1);
    }
    makeCallFromLocalStorage();
    setIsOnline(true);
  };
  useEffect(() => {
    if (navigator.onLine === false && isOpen) {
      setValidation(0);
    }
    if (navigator.onLine && !isOpen) {
      setValidation(1);
    }
  }, [isOpen, setValidation, validation]);

  const getStoreOptions = useCallback(() => {
    const endpoint = `storeOptions${organisation}`;
    axios
      .get(endpoints[endpoint])
      .then((res) => {
        setStoreOptions(res.data);
        window.localStorage.setItem(
          `storeOptions${organisation}`,
          JSON.stringify(res.data)
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setStoreOptions, organisation]);

  const getPricesData = useCallback(() => {
    //console.log('MediaMarkt component, get prices data 14.06 change');
    let endpoint = endpoints.pricesData;

    if (areaCode && areaCode !== 100) {
      endpoint = `${endpoints.pricesData}?area_code=${areaCode}`;
    }

    if (
      pathLocationCheck(location.pathname) === Routes.TIMPRIS ||
      pathLocationCheck(location.pathname) === Routes.PCE
    ) {
      if (areaCode && areaCode !== 100) {
        endpoint = `${endpoints.pricesData}?area_code=${areaCode}`;
      } else {
        endpoint = `${endpoints.pricesData}`;
      }
    }

    axios
      .get(endpoint)
      .then((res) => {
        setData(res.data);
        setLoadingData(false);
        setPriceApiConnection(true);
        const lastUpdate = {
          date: format(new Date(), dateFormats.dayMonthYear),
          time: format(new Date(), dateFormats.time),
        };
        setLastUpdated(lastUpdate);
        window.localStorage.setItem('lastUpdate', JSON.stringify(lastUpdate));
        window.localStorage.setItem('pricesData', JSON.stringify(res.data));
      })
      .catch((err) => {
        //setPricesError({ err: true, res: err.response.status });
        if (err.response.status === 503) {
          setValidation(0);
        }
        setLoadingData(false);
      });
  }, [areaCode, setLoadingData, setValidation]);

  const getStartDate = useCallback(() => {
    const endpoint = endpoints.startDate;
    axios
      .get(endpoint)
      .then((res) => {
        setFacilityStartDate(res.data);
        setStartDate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [setFacilityStartDate, setStartDate]);

  const getAgents = useCallback(() => {
    if (pathLocationCheck(location.pathname) !== Routes.LEADS) {
      const endpoint = `agentId${organisation}`;
      axios
        .get(endpoints[endpoint])
        .then((res) => {
          setAgentsList(res.data);
          window.localStorage.setItem(
            `agentsList${organisation}`,
            JSON.stringify(res.data)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [setAgentsList, organisation]);

  const getChartData = useCallback(() => {
    let endpoint = endpoints.chartData;

    if (areaCode) {
      endpoint += `?area_code=${areaCode}`;
    }

    const currentRoute = pathLocationCheck(location.pathname);

    if (currentRoute === Routes.TIMPRIS) {
      endpoint +=
        areaCode && areaCode !== 100
          ? `&contract=promo2022timpris`
          : `?contract=promo2022timpris`;
    } else if (currentRoute === Routes.PCE) {
      endpoint +=
        areaCode && areaCode !== 100
          ? `&contract=pcecampaign`
          : `?contract=pcecampaign`;
    }

    axios
      .get(endpoint)
      .then((res) => {
        setChartApiConnection(true);
        if (Array.isArray(res.data)) {
          setChartData(res.data);
        } else {
          console.warn('Received data is not an array', {
            receivedType: typeof res.data,
            data: res.data,
          });
          setChartData([]); // Fallback to an empty array
        }
        window.localStorage.setItem('chartData', JSON.stringify(res.data));
      })
      .catch((err) => {
        console.error('Error in API call', { error: err });
        setChartApiConnection(false);
        if (err.response && err.response.status === 503) {
          setValidation(0);
        } else {
          console.error('Unhandled API response status', {
            status: err.response?.status,
          });
          setValidation(-1); // Fallback validation state
        }
      });
  }, [areaCode, setChartData, setValidation, location.pathname]);

  useEffect(() => {
    if (isOnline && !isOpen) {
      getPricesData();
      getStoreOptions();
      getAgents();
      getStartDate();
      getChartData();
    }
  }, [
    getPricesData,
    getStoreOptions,
    getAgents,
    getStartDate,
    getChartData,
    isOnline,
    isOpen,
  ]);

  const handleOpenModal = () => {
    setIsOnline(isOnline);
    document.body.style.overflow = 'hidden';
    setIsOpen(true);
    setIsMinimizes(false);
  };

  useEffect(() => {
    if ((!isOpen && isMinimized) || (!isOpen && !isMinimized)) {
      setShowModal('hidden');
    } else {
      setShowModal('visible');
    }
  }, [isMinimized, isOpen]);

  const handleOnlinePriceData = () => {
    if (navigator.onLine && !priceApiConnection && cachedPricesData) {
      return cachedPricesData;
    }
    if (navigator.onLine) {
      return pricesData;
    } else return cachedPricesData;
  };

  const handleResetNoStepperModal = () => {
    setLoading(false);
  };
  return (
    <div className={styles.fullPage}>
      <div className={classnames(styles.container, styles.brandLogoContainer)}>
        <Image imageUrl={telinetLogo} alt="logo" />
        <ConditionalRender
          shouldRender={
            (lastUpdated && lastUpdated.date) ||
            !isOnline ||
            !chartApiConnection ||
            !priceApiConnection
          }
        >
          <Widget
            date={
              !isOnline ||
              ((!chartApiConnection || !priceApiConnection) && isOnline)
                ? cachedRequestData && cachedRequestData.date
                : lastUpdated.date
            }
            time={
              !isOnline ||
              ((!chartApiConnection || !priceApiConnection) && isOnline)
                ? cachedRequestData && cachedRequestData.time
                : lastUpdated.time
            }
            isOnline={isOnline}
            timeUpdated={timeUpdated}
          />
        </ConditionalRender>
      </div>
      <Agreements
        data={handleOnlinePriceData()}
        loading={navigator.onLine ? loadingData : false}
        error={pricesError}
        handleOpenModal={handleOpenModal}
      />
      <Prices
        data={handleOnlinePriceData()}
        loading={navigator.onLine ? loadingData : false}
        error={pricesError}
      />
      <History
        apiConnection={chartApiConnection}
        loading={navigator.onLine ? loadingData : false}
        isTimprisContract={
          pathLocationCheck(location.pathname) === Routes.TIMPRIS || Routes.PCE
            ? true
            : false
        }
      />

      <div className={styles.bottomButton}>
        <Image imageUrl={separatorDark} center />
        <Button
          primaryButton
          text={
            pathLocationCheck(location.pathname) === Routes.LEADS
              ? 'Intresseanmälan'
              : 'TECKNA DETTA AVTAL'
          }
          centerAlign
          leadsStylesButton={
            pathLocationCheck(location.pathname) === Routes.LEADS
          }
          onClick={() => {
            handleOpenModal();
          }}
        />
      </div>
      <div style={{ visibility: showModal }}>
        <Modal
          handleMinimizedModal={setShowModal}
          headerTitle={
            pathLocationCheck(location.pathname) === Routes.LEADS
              ? 'Intresseanmälan'
              : 'Registrera kund'
          }
          setIsMinimizes={setIsMinimizes}
          handleOpenModal={setIsOpen}
        >
          <ConditionalRender
            shouldRender={
              pathLocationCheck(location.pathname) === Routes.MEDIA_MARKT ||
              pathLocationCheck(location.pathname) === Routes.PERSONAL_BEST ||
              pathLocationCheck(location.pathname) === Routes.EVENT ||
              pathLocationCheck(location.pathname) === Routes.TIMPRIS ||
              pathLocationCheck(location.pathname) === Routes.PCE
            }
          >
            <AssignmentModalContent
              isOpen={isOpen}
              isMinimized={isMinimized}
              setIsOnline={setIsOnline}
              makeCallFromLocalStorage={makeCallFromLocalStorage}
              isOnline={isOnline}
            />
          </ConditionalRender>
          <ConditionalRender
            shouldRender={pathLocationCheck(location.pathname) === Routes.LEADS}
          >
            <NoStepperModalContent
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isMinimized={isMinimized}
              makeCallFromLocalStorage={makeCallFromLocalStorage}
            />
          </ConditionalRender>
        </Modal>
      </div>
      {isMinimized && showModal === 'hidden' && (
        <div>
          <MinimizedModal
            handleMaximize={setShowModal}
            closeModal={setIsOpen}
            setIsMinimizes={setIsMinimizes}
          />
        </div>
      )}
      <ToastContainer autoClose={1500} />
    </div>
  );
};
