import React from 'react';
import classnames from 'classnames';
import { Icon } from 'components/Icon';
import styles from './styles.module.scss';

const NavigationStep = ({ step, className, isPassed }) => {
  return (
    <div className={className}>
      <div
        className={classnames(
          styles.step,
          step.completed && styles.stepCompleted,
          step.selected && styles.stepSelected
        )}
      >
        <div
          className={classnames(styles.line, isPassed && styles.passedStepLine)}
        ></div>
        <div className={styles.iconWrap}>
          <Icon icon={step.icon} className={styles.modalStepIcon} />
        </div>
      </div>
    </div>
  );
};
export default NavigationStep;
