import React from 'react';
import { Text } from 'components/Text';
import { Spinner } from 'components/Spinner';
import { currencies } from 'utils/constants';
import styles from './styles.module.scss';
import ConditionalRender from 'components/ConditionalRender';

export const Price = ({
  label,
  price,
  currencyKr,
  currencyOre,
  bold,
  loading,
  error,
}) => (
  <div className={styles.priceWrap}>
    <Text medium>{label}</Text>
    {error && error.err && error.res !== 503 ? (
      <p>saknas</p>
    ) : (
      <Text bold={bold} medium>
        <div className={styles.priceValueWrap}>
          {loading ? <Spinner /> : <p>{price}</p>}

          <ConditionalRender shouldRender={currencyKr || currencyOre}>
            <p>
              {currencyKr && currencies.kr} {currencyOre && currencies.ore}
            </p>
          </ConditionalRender>
        </div>
      </Text>
    )}
  </div>
);
