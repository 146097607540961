import React, { useReducer } from 'react';
import { questions } from './QuestionsAnswers';

/*import './styles.module.scss'; */

import './quiz.css';
import logo from './Telinet-logo.png';

const initialState = {
  currentQuestionIndex: 0,
  correctAnswers: 0,
  showScore: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'answer':
      return {
        ...state,
        correctAnswers: state.correctAnswers + (action.isCorrect ? 1 : 0),
        currentQuestionIndex: state.currentQuestionIndex + 1,
      };
    case 'restart':
      return initialState;
    case 'showScore':
      return { ...state, showScore: true };
    default:
      throw new Error();
  }
}

function Question({ question, onAnswer }) {
  return (
    <div className="question-section">
      <div className="question-count">
        <span>Fråga {question.id}</span>/{questions.length}
      </div>
      <div className="question-text">{question.text}</div>
      <div className="answer-section">
        {question.answers.map((answer, index) => (
          <button
            key={index}
            onClick={() => onAnswer(answer === question.correctAnswer)}
          >
            {answer}
          </button>
        ))}
      </div>
    </div>
  );
}

function Quiz() {
  const [state, dispatch] = useReducer(reducer, initialState);

  if (state.currentQuestionIndex >= questions.length && !state.showScore) {
    dispatch({ type: 'showScore' });
  }

  const handleAnswer = (isCorrect) => {
    dispatch({ type: 'answer', isCorrect });
  };

  const handleRestart = () => {
    dispatch({ type: 'restart' });
  };

  return (
    <div className="app">
      <div className="imglogo">
        <img src={logo} alt="Logo" />
      </div>
      <h1>Quiz</h1>
      {state.showScore ? (
        <div className="score-section">
          Bra jobbat! Du fick {state.correctAnswers} av {questions.length} rätt!
          <button className="quizbtn" onClick={handleRestart}>
            Börja om quizen
          </button>
        </div>
      ) : (
        <Question
          question={{
            ...questions[state.currentQuestionIndex],
            id: state.currentQuestionIndex + 1,
          }}
          onAnswer={handleAnswer}
        />
      )}
    </div>
  );
}

export default Quiz;
