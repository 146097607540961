import { stringWithOnlyNumbers } from 'utils/areaCode';

export const validation = (personalNumber) => {
  const perNum = personalNumber.replace(/[^0-9]/g, '');
  const dayNumber = perNum.substring(4, 6);
  const monthNumber = perNum.substring(2, 4);

  // check 2 digits starting at position 4 to be in range 1- 31 (days in month)
  const validDay =
    dayNumber !== '' &&
    perNum.length <= 10 &&
    dayNumber <= 31 &&
    dayNumber !== 0
      ? true
      : false;

  // check 2 digits after position 2 to be greater than 12 (months in year)
  const validMonth =
    monthNumber !== '' && perNum.length <= 10 && monthNumber <= 12
      ? true
      : false;

  // luhn algorithm
  let sum = 0;

  const numDigits = perNum.length;
  const parity = numDigits % 2;
  for (var i = 0; i < numDigits; i = i + 1) {
    let digit = parseInt(perNum.charAt(i), 10);
    if (i % 2 === parity) {
      digit *= 2;
    }
    if (digit > 9) {
      digit -= 9;
    }
    sum += digit;
  }
  return sum % 10 === 0 && validMonth && validDay;
};

export const charactersValidation = (personalNumber) => {
  const firstTwoDigits = personalNumber.substring(0, 2);
  const firstFourDigits = personalNumber.substring(0, 4);
  const includesSlash = personalNumber.includes('-');
  const currentYear = new Date().getFullYear();
  const shortPersonalNumber = 10;
  const fullPersonalNumber = 12;

  if (
    personalNumber.length === fullPersonalNumber &&
    (firstTwoDigits === '19' ||
      (firstTwoDigits === '20' && firstFourDigits <= currentYear))
  ) {
    // eslint-disable-next-line no-useless-escape
    const slicedPersonalNumber = personalNumber.slice(2, 12).replace(/\-/g, '');

    const newPersonalNumber = `${slicedPersonalNumber.slice(
      0,
      6
    )}-${slicedPersonalNumber.slice(6)}`;
    return newPersonalNumber;
  }

  if (
    personalNumber.length === shortPersonalNumber &&
    !includesSlash &&
    stringWithOnlyNumbers(personalNumber)
  ) {
    const newPersonalNumber = `${personalNumber.slice(
      0,
      6
    )}-${personalNumber.slice(6)}`;
    return newPersonalNumber;
  }
  return personalNumber.replace(/\s+/g, '');
};
