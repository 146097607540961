import React from 'react';

import { BoxWrapper } from 'components/BoxWrapper';
import { Spinner } from 'components/Spinner';

import { currencies } from 'utils/constants';
import styles from './styles.module.scss';
import { Text } from 'components/Text';

export const ComparisonPrice = ({ data, loading, error }) => (
  <div className={styles.comparisonPriceWrap}>
    <BoxWrapper light className={styles.comparisonPrice}>
      <Text large bold>
        <p>20 000 {currencies.kWh}</p>
      </Text>
      {error.err && error.res !== 503 ? (
        <p className={styles.errorMessage}>saknas</p>
      ) : (
        <Text small className={styles.price}>
          {loading ? <Spinner /> : <p>{data.totalPrice20000}</p>}

          <p className={loading ? styles.priceLoading : ''}>
            &nbsp;
            {currencies.unitPrice}
          </p>
        </Text>
      )}
    </BoxWrapper>

    <BoxWrapper light className={styles.comparisonPrice}>
      <Text large bold>
        <p>5 000 {currencies.kWh}</p>
      </Text>
      {error.err && error.res !== 503 ? (
        <p className={styles.errorMessage}>saknas</p>
      ) : (
        <Text small className={styles.price}>
          {loading ? <Spinner /> : <p>{data.totalPrice5000}</p>}

          <p className={loading ? styles.priceLoading : ''}>
            &nbsp;
            {currencies.unitPrice}
          </p>
        </Text>
      )}
    </BoxWrapper>
    <BoxWrapper light className={styles.comparisonPrice}>
      <Text large bold>
        <p>2 000 {currencies.kWh}</p>
      </Text>
      {error.err && error.res !== 503 ? (
        <p className={styles.errorMessage}>saknas</p>
      ) : (
        <Text small className={styles.price}>
          {loading ? <Spinner /> : <p>{data.totalPrice2000}</p>}

          <p className={loading ? styles.priceLoading : ''}>
            &nbsp;
            {currencies.unitPrice}
          </p>
        </Text>
      )}
    </BoxWrapper>
  </div>
);
